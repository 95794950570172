import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

// import SignIn1 from './component/signin1/signin1';
// import Signup from './component/signin1/signup';
// import Dashboard from './component/dashboard/dashboard';
// import logout from './component/dashboard/logout';
// import Newinvoice from './component/dashboard/newinvoice';
// import Payments from './component/transactions/payment';
// import Newreceipt from './component/transactions/newreceipt';
// import Allreceipts from './component/transactions/allreceipts';
// import Allquotes from './component/transactions/allquotes';
// import Newquote from './component/transactions/newquote';
import Taxreturn from './component/transactions/taxreturn';
import Changepassword from './component/users/changepassword';

export default function BasicExample() {
  return (
    <Router>
      <div>
       

        <Switch>
          <Route exact path="/" component={Taxreturn}/>
          {/* <Route path="/signin" component={SignIn1}/>
          <Route path="/Signup" component={Signup}/>
          <Route path="/newinvoice" component={Newinvoice}/>
          <Route path="/taxreturn" component={Taxreturn}/>
          <Route path="/newreceipt" component={Newreceipt}/>
          <Route path="/newquote" component={Newquote}/>
          <Route path="/allreceipts" component={Allreceipts}/>
          <Route path="/allquotes" component={Allquotes}/>
          <Route path="/payments" component={Payments}/>
          <Route path="/dashboard" component={Dashboard}/>
          <Route path="/logout" component={logout}/> */}
          <Route path="/taxfile" component={Changepassword}/>
          {/* <Route path="/payments" component={Payments}/> */}

         
            
          
        </Switch>
      </div>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.

function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}


