import React, {useState,useEffect, useRef} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import { PDFDocument } from 'pdf-lib';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';


function Taxreturn(){
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const storedData = JSON.parse(localStorage.getItem('formData'));
  const [state , setState] = useState({
    corp_name: storedData ? storedData.corp_name : '',
    bus_number: storedData ? storedData.bus_number : '',
    tax_start: storedData ? storedData.tax_start : '',
    tax_end : storedData ? storedData.tax_end : '',
    first_name : storedData ? storedData.first_name : '',
    last_name : storedData ? storedData.last_name : '',
    position : storedData ? storedData.position : '',
    address: storedData ? storedData.address : '',
    phone : storedData ? storedData.phone : '',
    bus_address: storedData ? storedData.bus_address : '',
    city: storedData ? storedData.city : '',
    province: storedData ? storedData.province : '',
    zip: storedData ? storedData.zip : '',
    mail_address: storedData ? storedData.mail_address : '',
    mail_city: storedData ? storedData.mail_city : '',
    mail_province: storedData ? storedData.mail_province : '',
    mail_zip: storedData ? storedData.mail_zip : '',
    corp_type: storedData ? storedData.corp_type : '',
    corp_other: storedData ? storedData.corp_other : '',
    corp_date: storedData ? storedData.corp_date : '',
    aquisition: storedData ? storedData.aquisition : '',
    aquisition_date: storedData ? storedData.aquisition_date : '',
    tax_year_date: storedData ? storedData.tax_year_date : '',
    professional_corp: storedData ? storedData.professional_corp : '',
    first_filling: storedData ? storedData.first_filling : '',
    first_amalgamation: storedData ? storedData.first_amalgamation : '',
    wind_up: storedData ? storedData.wind_up : '',
    final_tax: storedData ? storedData.final_tax : '',
    final_return: storedData ? storedData.final_return : '',
    book_address: storedData ? storedData.book_address : '',
    book_city: storedData ? storedData.book_city : '',
    book_province: storedData ? storedData.book_province : '',
    book_zip: storedData ? storedData.book_zip : '',
    election_currency: storedData ? storedData.election_currency : '',
    resident: storedData ? storedData.resident : '',
    residence_country: storedData ? storedData.residence_country : '',
    exemption: storedData ? storedData.exemption : '',
    related: storedData ? storedData.related : '',
    associated: storedData ? storedData.associated : '',
    shareholders: storedData ? storedData.shareholders : '',
    inactive: storedData ? storedData.inactive : '',
    international_fi: storedData ? storedData.international_fi : '',
    imigrate: storedData ? storedData.imigrate : '',
    emigrate: storedData ? storedData.emigrate : '',
    principal_percentage2: storedData ? storedData.principal_percentage2 : '',
    principal_percentage1: storedData ? storedData.principal_percentage1 : '',
    principal_percentage: storedData ? storedData.principal_percentage : '',
    principal_prod:   storedData ? storedData.principal_prod : '',
    principal_prod1:   storedData ? storedData.principal_prod1 : '',
    principal_prod2:   storedData ? storedData.principal_prod2 : '',
    signing_officer: storedData ? storedData.signing_officer : '',
    signing_phone: storedData ? storedData.signing_phone : '',
    contact_person: storedData ? storedData.contact_person : '',
})


const [showBottomCard, setShowBottomCard] = useState(false);
const bottomCardRef = useRef(null);
const upperCardRef = useRef(null);





  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }  

      
  const handlechangepassword = (e) => {
    // Save the form data to localStorage
    localStorage.setItem('formData', JSON.stringify(state));
  
    // Create the query string
    const queryString = Object.keys(state)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(state[key]))
      .join('&');
  
    const nextUrl = '/taxfile?' + queryString;
  
    // Navigate to the next page
    window.location.href = nextUrl;
  };


  // Retrieve the saved form data from localStorage
  // document.addEventListener('DOMContentLoaded', () => {
  //   // Your code here
  //   const savedData = localStorage.getItem('formData');
  //   if (savedData) {
  //     const parsedData = JSON.parse(savedData);
  //     const firstNameInput = document.querySelector('[name="first_name"]');
  //     const lastName = document.querySelector('[name="last_name"]');
  //     const corpNameinput = document.querySelector('[name="corp_name"]');
  //     const busnameInput = document.querySelector('[name="bus_number"]');
      
  //     // if (firstNameInput) {
  //       firstNameInput.value = parsedData.first_name;
  //       lastName.value = parsedData.lastName;
  //       corpNameinput.value = parsedData.corp_name;
  //       busnameInput.value = parsedData.bus_number;
  //     // }
  //   }
  // });
  

  

const handleGetFieldNames = (e) => {
    upperCardRef.current.scrollIntoView({ behavior: 'smooth' });
}

const upper = (e) => {
  bottomCardRef.current.scrollIntoView({ behavior: 'smooth' });
}


  
      return (
              <div>
                   <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
        <div id="kt_aside" className="aside" data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle">
        <div className="aside-menu flex-column-fluid">
              <div className="hover-scroll-overlay-y px-2 my-5 my-lg-5" id="kt_aside_menu_wrapper" data-kt-scroll="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="{default: '#kt_aside_toolbar, #kt_aside_footer', lg: '#kt_header, #kt_aside_toolbar, #kt_aside_footer'}" data-kt-scroll-wrappers="#kt_aside_menu" data-kt-scroll-offset="5px">
                <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500" id="#kt_aside_menu" data-kt-menu="true">
                  <div data-kt-menu-trigger="click" className="menu-item here show  menu-accordion">
                    <span className="menu-link">
                      <span className="menu-icon">
                        <span className="svg-icon svg-icon-2">
                          <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x={2} y={2} width={9} height={9} rx={2} fill="currentColor" />
                            <rect opacity="0.3" x={13} y={2} width={9} height={9} rx={2} fill="currentColor" />
                            <rect opacity="0.3" x={13} y={13} width={9} height={9} rx={2} fill="currentColor" />
                            <rect opacity="0.3" x={2} y={13} width={9} height={9} rx={2} fill="currentColor" />
                          </svg>
                        </span>
                      </span>
                      <a className="menu-title active">Dashboard</a>
                      <span className="menu-arrow" />
                    </span>
                    <div className="menu-sub menu-sub-accordion">
                      <div className="menu-item">
                        <a className="menu-link active" href="/">
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot" />
                          </span>
                          <span className="menu-title">Home</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <div id="kt_header" style={{}} className="header align-items-stretch">
              <div className="header-brand">
                <a href="/">
                  {/* <img alt="Logo" src="assets/media/logo2.png" className="dpp" /> */}
                  <h1 style={{color: 'white'}}>SMART RETURN</h1>
                </a>
                <div id="kt_aside_toggle" className="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
                  <span className="svg-icon svg-icon-1 me-n1 minimize-default">
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.3" x="8.5" y={11} width={12} height={2} rx={1} fill="currentColor" />
                      <path d="M10.3687 11.6927L12.1244 10.2297C12.5946 9.83785 12.6268 9.12683 12.194 8.69401C11.8043 8.3043 11.1784 8.28591 10.7664 8.65206L7.84084 11.2526C7.39332 11.6504 7.39332 12.3496 7.84084 12.7474L10.7664 15.3479C11.1784 15.7141 11.8043 15.6957 12.194 15.306C12.6268 14.8732 12.5946 14.1621 12.1244 13.7703L10.3687 12.3073C10.1768 12.1474 10.1768 11.8526 10.3687 11.6927Z" fill="currentColor" />
                      <path opacity="0.5" d="M16 5V6C16 6.55228 15.5523 7 15 7C14.4477 7 14 6.55228 14 6C14 5.44772 13.5523 5 13 5H6C5.44771 5 5 5.44772 5 6V18C5 18.5523 5.44771 19 6 19H13C13.5523 19 14 18.5523 14 18C14 17.4477 14.4477 17 15 17C15.5523 17 16 17.4477 16 18V19C16 20.1046 15.1046 21 14 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H14C15.1046 3 16 3.89543 16 5Z" fill="currentColor" />
                    </svg>
                  </span>
                  <span className="svg-icon svg-icon-1 minimize-active">
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.3" width={12} height={2} rx={1} transform="matrix(-1 0 0 1 15.5 11)" fill="currentColor" />
                      <path d="M13.6313 11.6927L11.8756 10.2297C11.4054 9.83785 11.3732 9.12683 11.806 8.69401C12.1957 8.3043 12.8216 8.28591 13.2336 8.65206L16.1592 11.2526C16.6067 11.6504 16.6067 12.3496 16.1592 12.7474L13.2336 15.3479C12.8216 15.7141 12.1957 15.6957 11.806 15.306C11.3732 14.8732 11.4054 14.1621 11.8756 13.7703L13.6313 12.3073C13.8232 12.1474 13.8232 11.8526 13.6313 11.6927Z" fill="currentColor" />
                      <path d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z" fill="currentColor" />
                    </svg>
                  </span>
                </div>
                <div className="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
                  <div className="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
                    <span className="svg-icon svg-icon-1">
                      <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor" />
                        <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor" />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div className="toolbar d-flex align-items-stretch">
                <div className="container-fluid py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
                  <div className="page-title d-flex justify-content-center flex-column me-5">
                    <h1 className="d-flex flex-column text-dark fw-bold fs-3 mb-0">File Null Returns</h1>
                  </div>
                  <div className="d-flex align-items-stretch overflow-auto pt-3 pt-lg-0">
                    <div className="d-flex align-items-center">
                      <div className="bullet bg-secondary h-35px w-1px mx-5" />
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
              <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-xxl">
                  <div className="row g-5 g-xl-8" ref={upperCardRef}>
                    <div className="col-xl-12">
                      <div className="card card-xl-stretch mb-5 mb-xl-8">
                        <div className="card-header border-0 pt-5">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold fs-3 mb-1">Personal Information</span>
                          </h3>
                          <div className="card-toolbar">
                          </div>
                        </div>
                        <div className="card-body py-3">
                          <div className="tab-content">
                    
                        <div className='row'>
                            <div className="fv-row mb-3 col-lg-6">
                            <label>Enter First name</label>
                                <input type="text"  value={state.first_name} onChange={handleChange} id="first_name" name="first_name" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                            <label>Enter Last name</label>
                                <input type="text"  value={state.last_name} onChange={handleChange} id="last_name" name="last_name" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                            <label>Enter Telephone number</label>
                                <input type="text"  value={state.phone} onChange={handleChange} id="phone" name="phone" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                            <label>Enter Position at your organization</label>
                                <input type="text"  value={state.position} onChange={handleChange} id="position" name="position" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                            <label>Enter Corporation's name</label>
                                <input type="text"  value={state.corp_name} onChange={handleChange} id="corp_name" name="corp_name" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                                <label>Enter Business number</label>
                                <input type="text" value={state.bus_number} onChange={handleChange} id="bus_number" name="bus_number  " className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                                <label>Tax year start</label>
                                <input type="date" value={state.tax_start} onChange={handleChange} id="tax_start" name="tax_start" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                                <label>Tax year-end</label>
                                <input type="date"  value={state.tax_end} onChange={handleChange} id="tax_end" name="tax_end" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                                <label>Address of Head office</label>
                                <input type="text"  value={state.address} onChange={handleChange} id="address" name="address" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                                <label>City</label>
                                <input type="text"  value={state.city} onChange={handleChange} id="city" name="city" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                                <label>Province</label>
                                <input type="text" value={state.province} onChange={handleChange} id="province" name="province" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                                <label>Postal or ZIP code</label>
                                <input type="text"  value={state.zip} onChange={handleChange} id="zip" name="zip" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                                <label>Mailing Address</label>
                                <input type="text"  value={state.mail_address} onChange={handleChange} id="mail_address" name="mail_address" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                                <label>Mailing Address City</label>
                                <input type="text"  value={state.mail_city} onChange={handleChange} id="mail_city" name="mail_city" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                                <label>Mailing Address Province</label>
                                <input type="text"  value={state.mail_province} onChange={handleChange} id="mail_province" name="mail_province" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                                <label>Mailing Address Postal or ZIP code</label>
                                <input type="text"  value={state.mail_zip} onChange={handleChange} id="mail_zip" name="mail_zip" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                                <label>Location of books and records</label>
                                <input type="text"  value={state.book_address} onChange={handleChange} id="book_address" name="book_address" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                                <label>Location of books and records City</label>
                                <input type="text"  value={state.book_city} onChange={handleChange} id="book_city" name="book_city" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                                <label>Location of books and records Province</label>
                                <input type="text"  value={state.book_province} onChange={handleChange} id="book_province" name="book_province" className="form-control bg-transparent" />
                            </div>
                            <div className="fv-row mb-3 col-lg-6">
                                <label>Mailing Address Postal or ZIP code</label>
                                <input type="text"  value={state.book_zip} onChange={handleChange} id="book_zip" name="book_zip" className="form-control bg-transparent" />
                            </div>

                            <div className="fv-row mb-3 col-lg-6">
                                <label>Type of corporation at the end of the tax year </label>
                                <select className="form-control bg-transparent" onChange={handleChange}  value={state.corp_type} id="corp_type" name="corp_type">
                                  <option value='' >--</option>
                                  <option value='Canadian-controlled private corporation (CCPC)' >Canadian-controlled private corporation (CCPC)</option>
                                  <option value='Other private corporation' >Other private corporation</option>
                                  <option  value='Public corporation'>Public corporation</option>
                                  <option  value='Corporation controlled by a public corporation'>Corporation controlled by a public corporation</option>
                                  {/* <option  value='Other corporation' id="corp_type" name="corp_type" >Other corporation(specify)</option> */}
                                </select>
                                
                            </div>

                            <div className="fv-row mb-3 col-lg-6">
                                <label>If Other type of corporation, please specify</label>
                                <input type="text"  value={state.corp_other} onChange={handleChange} id="corp_other" name="corp_other" className="form-control bg-transparent" />
                            </div>

                            
                            <div className="fv-row mb-3 col-lg-6">
                                <label>If the type of corporation changed during the tax year, provide the effective date of the change</label>
                                <input type="date"  value={state.corp_date} onChange={handleChange} id="corp_date" name="corp_date" className="form-control bg-transparent" />
                            </div>
                            </div>
                          </div>
                            <button onClick={upper} style={{float:'right'}} className="btn btn-primary">
                            <span className="indicator-label">Next</span>
                            </button>
                        </div>
                      </div>

                      
                     </div>
                  </div>
                 
                  <div className="row g-5 g-xl-12" ref={bottomCardRef}>
                    <div className="col-xl-12">
                      <div className="card card-xl-stretch mb-5 mb-xl-12">
                        <div className="card-header border-0 pt-5">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold fs-3 mb-1">T2 Corporation Income Tax Return</span>
                          </h3>
                          <div className="card-toolbar">
                          </div>
                        </div>
                    <div className="card-body py-3">
                        <div className="tab-content">
                          <div className="py-5 mb-5">
                            <div className="rounded border p-10">
                                    <p></p>
                                    <div className="fv-row mb-6 col-lg-6">
                                <label><b> Has there been an acquisition of control resulting in the application of subsection 249(4) since the tax year start</b></label>
                                <select className="form-control bg-transparent" onChange={handleChange}  value={state.aquisition} id="aquisition" name="aquisition">
                                  <option value='' >--</option>
                                  <option value='YES' >YES</option>
                                  <option value='NO'>NO</option>
                                </select>
                            </div>

                                    <p></p>
                                    <div className="fv-row mb-6 col-lg-6">
                                <label><b>Is the date on 'tax year-end' field a deemed tax year-end according to subsection 249(3.1)?</b></label>
                                <select className="form-control bg-transparent" onChange={handleChange}  value={state.tax_year_date} id="tax_year_date" name="tax_year_date">
                                  <option value='' >--</option>
                                  <option value='YES' >YES</option>
                                  <option value='NO'>NO</option>
                                </select>
                            </div>

                            <b></b>
                                    <p></p>
                            <div className="fv-row mb-6 col-lg-6">
                                <label><b>Is the corporation a professional corporation that is a member of a partnership?</b></label>
                                <select className="form-control bg-transparent" onChange={handleChange}  value={state.professional_corp} id="professional_corp" name="professional_corp">
                                  <option value='' >--</option>
                                  <option value='YES' >YES</option>
                                  <option value='NO'>NO</option>
                                </select>
                            </div>

                            <b>Is this the first year of filing after:</b>
                                    <p></p>
                                    <p></p>
                            <div className="fv-row mb-6 col-lg-6">
                                <label>Incorporation</label>
                                <select className="form-control bg-transparent" onChange={handleChange}  value={state.first_filling} id="first_filling" name="first_filling">
                                  <option value='' >--</option>
                                  <option value='YES' >YES</option>
                                  <option value='NO'>NO</option>
                                </select>
                            </div>

                            <div className="fv-row mb-6 col-lg-6">
                                <label>Amalgamation</label>
                                <select className="form-control bg-transparent" onChange={handleChange}  value={state.first_amalgamation} id="first_amalgamation" name="first_amalgamation">
                                  <option value='' >--</option>
                                  <option value='YES' >YES</option>
                                  <option value='NO'>NO</option>
                                </select>
                            </div>

                            <b></b>
                                    <p></p>
                            <div className="fv-row mb-6 col-lg-6">
                                <label><b>Has there been a wind-up of a subsidiary under section 88 during the current tax year?</b></label>
                                <select className="form-control bg-transparent" onChange={handleChange}  value={state.wind_up} id="wind_up" name="wind_up">
                                  <option value='' >--</option>
                                  <option value='YES' >YES</option>
                                  <option value='NO'>NO</option>
                                </select>
                            </div>

                              <p></p>
                            <div className="fv-row mb-6 col-lg-6">
                                <label><b>Is this the final tax year before amalgamation?</b></label>
                                <select className="form-control bg-transparent" onChange={handleChange}  value={state.final_tax} id="final_tax" name="final_tax">
                                  <option value='' >--</option>
                                  <option value='YES' >YES</option>
                                  <option value='NO'>NO</option>
                                </select>
                            </div>

                                      <p></p>
                              <div className="fv-row mb-6 col-lg-6">
                                <label><b>Is this the final return up to dissolution?</b></label>
                                <select className="form-control bg-transparent" onChange={handleChange}  value={state.final_return} id="final_return" name="final_return">
                                  <option value='' >--</option>
                                  <option value='YES' >YES</option>
                                  <option value='NO'>NO</option>
                                </select>
                            </div>

                            <div className="fv-row mb-3 col-lg-6">
                                <label><b>If an election was made under section 261, state the functional currency used</b></label>
                                          <input type="text"  value={state.election_currency} onChange={handleChange} id="election_currency" name="election_currency" className="form-control bg-transparent" />
                            </div>

                                    <p></p>
                                    <p></p>
                            <div className="col-sm-12">
                            <div className="fv-row mb-6 col-lg-6">
                                <label><b>Is the corporation a resident of Canada?</b></label>
                                <select className="form-control bg-transparent" onChange={handleChange}  value={state.resident} id="resident" name="resident">
                                  <option value='' >--</option>
                                  <option value='YES' >YES</option>
                                  <option value='NO'>NO</option>
                                </select>
                            </div>
                            
                            <div className="col-sm-10">
                                <div className="fv-row mb-6 col-lg-6">
                                <label>If no, give the country of residence.</label>
                                <input type="text"  value={state.residence_country} onChange={handleChange} id="residence_country" name="residence_country" className="form-control bg-transparent" />
                            </div>
                            </div>
                            </div>

                            <b></b>
                                    <p></p>
                             <div className="fv-row mb-3 col-lg-6">
                                <label><b>Is the non-resident corporation claiming an exemption under an income tax treaty?</b></label>
                                <select className="form-control bg-transparent" onChange={handleChange}  value={state.exemption} id="exemption" name="exemption">
                                  <option value='' >--</option>
                                  <option value='YES' >YES</option>
                                  <option value='NO'>NO</option>
                                </select>
                            </div>

                            <b></b>
                                    <p></p>
                                    <div className="fv-row mb-3 col-lg-6">
                                <label><b>Is the corporation related to any other corporations?</b></label>
                                <select className="form-control bg-transparent" onChange={handleChange}  value={state.related} id="related" name="related">
                                  <option value='' >--</option>
                                  <option value='YES' >YES</option>
                                  <option value='NO'>NO</option>
                                </select>
                            </div>

                            <b></b>
                            <div className="fv-row mb-3 col-lg-6">
                                <label><b>Is the corporation an associated CCPC?</b></label>
                                <select className="form-control bg-transparent" onChange={handleChange}  value={state.associated} id="associated" name="associated">
                                  <option value='' >--</option>
                                  <option value='YES' >YES</option>
                                  <option value='NO'>NO</option>
                                </select>
                            </div>
                                    <p></p>
                            <div className="fv-row mb-3 col-lg-6">
                                <label><b>For private corporations: Does the corporation have any shareholders who own 10% or more of the corporation's common and/or preferred shares?</b></label>
                                <select className="form-control bg-transparent" onChange={handleChange}  value={state.shareholders} id="shareholders" name="shareholders">
                                  <option value='' >--</option>
                                  <option value='YES' >YES</option>
                                  <option value='NO'>NO</option>
                                </select>
                            </div>
                              <br/>
                            <div className="fv-row mb-3 col-lg-6">
                                <label><b>Is the corporation inactive?</b></label>
                                <select className="form-control bg-transparent" onChange={handleChange}  value={state.inactive} id="inactive" name="inactive">
                                  <option value='' >--</option>
                                  <option value='YES' >YES</option>
                                  <option value='NO'>NO</option>
                                </select>
                            </div>
                            <br/>

                            <div className="fv-row mb-3 col-lg-6">
                                <label><b>Did the corporation use the International Financial Reporting Standards (IFRS) when it prepared its financial statements?</b></label>
                                <select className="form-control bg-transparent" onChange={handleChange}  value={state.international_fi} id="international_fi" name="international_fi">
                                  <option value='' >--</option>
                                  <option value='YES' >YES</option>
                                  <option value='NO'>NO</option>
                                </select>
                            </div>

                            <div className="col-sm-10">
                                <div className="fv-row mb-6 col-lg-6">
                                <label><b>Specify the three main principal products mined, manufactured, sold, constructed, or services provided, giving the approximate percentage of the total revenue that each product or service represents.</b></label>
                               <br/>
                               <br/>
                                <div className='row'>
                                <div className='col-8'>
                                  Product
                                <input type="text"  value={state.principal_prod} onChange={handleChange} id="principal_prod" name="principal_prod" className="form-control bg-transparent" />
                                </div>
                                <div className='col-4'>
                                % owned
                                <input type="text"  value={state.principal_percentage} placeholder='e.g. 10%' onChange={handleChange} id="principal_percentage" name="principal_percentage" className="form-control bg-transparent" />
                                </div>
                                </div>

                                <div className='row'>
                                  <p></p>
                                <div className='col-8'>
                                <input type="text"  value={state.principal_prod1} onChange={handleChange} id="principal_prod1" name="principal_prod1" className="form-control bg-transparent" />
                               </div>
                                <div className='col-4'>
                                <input type="text"  value={state.principal_percentage1}  onChange={handleChange} id="principal_percentage1" name="principal_percentage1" className="form-control bg-transparent" />
                                </div>
                                </div>

                                <div className='row'>
                                <p></p>
                                <div className='col-8'>
                                <input type="text"  value={state.principal_prod2} onChange={handleChange} id="principal_prod2" name="principal_prod2" className="form-control bg-transparent" />
                                </div>
                                <div className='col-4'>
                                <input type="text"  value={state.principal_percentage2}  onChange={handleChange} id="principal_percentage2" name="principal_percentage2" className="form-control bg-transparent" />
                                </div>
                                </div>
                            </div>
                            </div>


                            <br/>
                          <div className="fv-row mb-3 col-lg-6">
                            <label><b>Did the corporation emigrate from Canada during the tax year?</b></label>
                            <select className="form-control bg-transparent" onChange={handleChange}  value={state.emigrate} id="emigrate" name="emigrate">
                              <option value='' >--</option>
                              <option value='YES' >YES</option>
                              <option value='NO'>NO</option>
                            </select>
                          </div>
                          <br/>
                          <br/>
                          <div className="fv-row mb-3 col-lg-6">
                            <label><b>Did the corporation imigrate to Canada during the tax year?</b></label>
                            <select className="form-control bg-transparent" onChange={handleChange}  value={state.imigrate} id="imigrate" name="imigrate">
                              <option value='' >--</option>
                              <option value='YES' >YES</option>
                              <option value='NO'>NO</option>
                            </select>
                          </div>
                          <br/>
                          <br/>
                          <div className="fv-row mb-3 col-lg-6">
                            <label><b>Is the contact person the same as the authorized signing officer? If no, complete the information below</b></label>
                            <select className="form-control bg-transparent" onChange={handleChange}  value={state.contact_person} id="contact_person" name="contact_person">
                              <option value='' >--</option>
                              <option value='YES' >YES</option>
                              <option value='NO'>NO</option>
                            </select>
                          </div>
                          <br/>
                          <div className="fv-row mb-3 col-lg-6">
                            <label> Name of auhtorized signing officer</label>
                            <input type="text"  value={state.signing_officer}  onChange={handleChange} id="signing_officer" name="signing_officer" className="form-control bg-transparent" />
                          </div>
                          <br/>
                          <br/>
                          <div className="fv-row mb-3 col-lg-6">
                            <label> Phone Number  of auhtorized signing officer</label>
                            <input type="text"  value={state.signing_phone}  onChange={handleChange} id="signing_phone" name="signing_phone" className="form-control bg-transparent" />
                          </div>
                          <br/>
                          
                        </div>
                    </div>
                          <button onClick={handleGetFieldNames}  className="btn btn-danger">
                            <span className="indicator-label">Go up</span>
                            </button>

                            <button onClick={handlechangepassword} style={{float:'right'}} className="btn btn-primary">
                            <span className="indicator-label">Complete</span>
                            </button>
                        </div>
                      </div>

                      
                     </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
              <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
                <div className="text-dark order-2 order-md-1">
                  <span className="text-muted fw-semibold me-1">2022©</span>
                  <a href="https://instavoicing.com/" target="_blank" className="text-gray-800 text-hover-primary">Instavoicing Ltd</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
              </div>
               
        );
 
  };

export default Taxreturn;
