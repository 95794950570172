import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import PrintButton from './print';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import html2pdf from 'html2pdf.js';

function Changepassword (){
  const history = useHistory();
  const MySwal = withReactContent(Swal)

  const queryParams = new URLSearchParams(window.location.search);
  const state = {};

  for (const [key, value] of queryParams.entries()) {
    state[key] = value;
  }


  


  const today = new Date();
  var domToPdf = require('react-dom-to-pdf-byaasif');
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const currenDate = `${year}-${month}-${day}`;

  // const generatePDF = () => {
  //   var element = document.getElementById('printable-content');
  //   var options = {
  //     filename: 'SmartTaxReturn.pdf',
  //     jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' },
  //   };
  //   domToPdf(element, options, function() {
  //     console.log('done');
  //   });
  // };

  function printDiv(divId) {
    const printContents = document.getElementById(divId).innerHTML;
    const originalContents = document.body.innerHTML;
  
    document.body.innerHTML = printContents;
    window.print();
  
    document.body.innerHTML = originalContents;
  }



  async function downloadPdf(divId) {
    try {
      // Show loading message
      const loadingSwal = Swal.fire({
        title: 'Loading',
        text: 'Generating PDF...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
  
      const element = document.getElementById(divId);
      const options = {
        margin: 10,
        filename: 'SmartTaxReturn.pdf',
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' },
      };
      await html2pdf(element, options);
      loadingSwal.close();
      Swal.fire({
        icon: 'success',
        title: 'PDF Downloaded',
        text: 'Your PDF has been successfully downloaded!',
      });
    } catch (error) {
      // Handle errors
      console.error(error);
  
      // Close loading message on error
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while generating the PDF.',
      });
    }
  }


  const goback = (e) => {
    window.location.href='/'
  }    
     
  console.log(state.professional_corp)



      return (
              <div>
                   <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
        <div id="kt_aside" className="aside" data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle">
        <div className="aside-menu flex-column-fluid">
              <div className="hover-scroll-overlay-y px-2 my-5 my-lg-5" id="kt_aside_menu_wrapper" data-kt-scroll="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="{default: '#kt_aside_toolbar, #kt_aside_footer', lg: '#kt_header, #kt_aside_toolbar, #kt_aside_footer'}" data-kt-scroll-wrappers="#kt_aside_menu" data-kt-scroll-offset="5px">
                <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500" id="#kt_aside_menu" data-kt-menu="true">
                <div data-kt-menu-trigger="click" className="menu-item here show  menu-accordion">
                    <span className="menu-link">
                      <span className="menu-icon">
                        <span className="svg-icon svg-icon-2">
                          <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x={2} y={2} width={9} height={9} rx={2} fill="currentColor" />
                            <rect opacity="0.3" x={13} y={2} width={9} height={9} rx={2} fill="currentColor" />
                            <rect opacity="0.3" x={13} y={13} width={9} height={9} rx={2} fill="currentColor" />
                            <rect opacity="0.3" x={2} y={13} width={9} height={9} rx={2} fill="currentColor" />
                          </svg>
                        </span>
                      </span>
                      <a className="menu-title active">Dashboard</a>
                      <span className="menu-arrow" />
                    </span>
                    <div className="menu-sub menu-sub-accordion">
                      <div className="menu-item">
                        <a className="menu-link active" href="/">
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot" />
                          </span>
                          <span className="menu-title">Home</span>
                        </a>
                      </div>
                    </div>
                  </div>

                
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <div id="kt_header" style={{}} className="header align-items-stretch">
              <div className="header-brand">
                <a href="/">
                  {/* <img alt="Logo" src="assets/media/logo2.png" className="dpp" /> */}
                  <h1 style={{color: 'white'}}>SMART RETURN</h1>

                </a>
                <div id="kt_aside_toggle" className="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
                  <span className="svg-icon svg-icon-1 me-n1 minimize-default">
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.3" x="8.5" y={11} width={12} height={2} rx={1} fill="currentColor" />
                      <path d="M10.3687 11.6927L12.1244 10.2297C12.5946 9.83785 12.6268 9.12683 12.194 8.69401C11.8043 8.3043 11.1784 8.28591 10.7664 8.65206L7.84084 11.2526C7.39332 11.6504 7.39332 12.3496 7.84084 12.7474L10.7664 15.3479C11.1784 15.7141 11.8043 15.6957 12.194 15.306C12.6268 14.8732 12.5946 14.1621 12.1244 13.7703L10.3687 12.3073C10.1768 12.1474 10.1768 11.8526 10.3687 11.6927Z" fill="currentColor" />
                      <path opacity="0.5" d="M16 5V6C16 6.55228 15.5523 7 15 7C14.4477 7 14 6.55228 14 6C14 5.44772 13.5523 5 13 5H6C5.44771 5 5 5.44772 5 6V18C5 18.5523 5.44771 19 6 19H13C13.5523 19 14 18.5523 14 18C14 17.4477 14.4477 17 15 17C15.5523 17 16 17.4477 16 18V19C16 20.1046 15.1046 21 14 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H14C15.1046 3 16 3.89543 16 5Z" fill="currentColor" />
                    </svg>
                  </span>
                  <span className="svg-icon svg-icon-1 minimize-active">
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.3" width={12} height={2} rx={1} transform="matrix(-1 0 0 1 15.5 11)" fill="currentColor" />
                      <path d="M13.6313 11.6927L11.8756 10.2297C11.4054 9.83785 11.3732 9.12683 11.806 8.69401C12.1957 8.3043 12.8216 8.28591 13.2336 8.65206L16.1592 11.2526C16.6067 11.6504 16.6067 12.3496 16.1592 12.7474L13.2336 15.3479C12.8216 15.7141 12.1957 15.6957 11.806 15.306C11.3732 14.8732 11.4054 14.1621 11.8756 13.7703L13.6313 12.3073C13.8232 12.1474 13.8232 11.8526 13.6313 11.6927Z" fill="currentColor" />
                      <path d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z" fill="currentColor" />
                    </svg>
                  </span>
                </div>
                <div className="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
                  <div className="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
                    <span className="svg-icon svg-icon-1">
                      <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor" />
                        <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor" />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div className="toolbar d-flex align-items-stretch">
                <div className="container-fluid py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
                  <div className="page-title d-flex justify-content-center flex-column me-5">
                    <h1 className="d-flex flex-column text-dark fw-bold fs-3 mb-0"></h1>
                  </div>
                  <div className="d-flex align-items-stretch overflow-auto pt-3 pt-lg-0">
                    <div className="d-flex align-items-center">
                      <div className="bullet bg-secondary h-35px w-1px mx-5" />
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
           
            <div>
              <br/>
              <br/>
              <br/>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button onClick={goback} className='btn btn-danger btn-large'>Go Back</button>&nbsp;
            <button onClick={() => printDiv('printable-content')} className='btn btn-success btn-large'>Print</button>&nbsp;
            <button onClick={() => downloadPdf('printable-content')}   className='btn btn-primary btn-large'>Save as PDF</button>
            {/* <button onClick={() => generatePDF()}   className='btn btn-primary btn-large'>Save as PDF</button> */}
            
            
            </div>

            <div id="printable-content">
      
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
              <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-xxl">
                  <div className="row g-5 g-xl-8">
                    <div className="col-xl-12">
                      <div className="card card-xl-stretch mb-5 mb-xl-8">
                        <div className="card-header border-0 pt-5">
                        
                        
                        </div>
                        <div className="card-body">
                          <div className="tab-content">
                    
                          <div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />


        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\n\tp {margin: 0; padding: 0;}\t.ft10{font-size:11px;font-family:Helvetica;color:#000000;}\n\t.ft11{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft12{font-size:16px;font-family:Helvetica;color:#000000;}\n\t.ft13{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft14{font-size:13px;font-family:Helvetica;color:#000000;}\n\t.ft15{font-size:13px;font-family:Helvetica;color:#000000;}\n\t.ft16{font-size:8px;font-family:Helvetica;color:#000000;}\n\t.ft17{font-size:10px;line-height:15px;font-family:Helvetica;color:#000000;}\n\t.ft18{font-size:10px;line-height:13px;font-family:Helvetica;color:#000000;}\n" }} />
        <style dangerouslySetInnerHTML={{__html: "\n\t.borderless-input {\n\t  border: none;\n\t  outline: none;\n\t  font-weight: bold; \n\tfont-size: 12px; \n\tfont-family: 'Poppins'; \n\tbackground-color: transparent;}\n  " }} />
        <style dangerouslySetInnerHTML={{__html: "\n\t.borderless-input2 {\n\t  border: none;\n\t  outline: none;\n\t  font-weight: bold; \n\tfont-size: 12px; \n\tfont-family: 'Poppins'; \n\tbackground-color: white;}\n  " }} />
        <div id="page1-div" style={{position: 'relative', width: '918px', height: '1188px'}}>
          <img width={918} height={1188} src="target001.png" alt="background image" />
          <p style={{position: 'absolute', top: '32px', left: '811px', whiteSpace: 'nowrap'}} className="ft10"><b>Protected B&nbsp;</b></p>
          <p style={{position: 'absolute', top: '45px', left: '799px', whiteSpace: 'nowrap'}} className="ft11">when completed</p>
          <p style={{position: 'absolute', top: '85px', left: '226px', whiteSpace: 'nowrap'}} className="ft12"><b>Information Return for Corporations Filing Electronically</b></p>
          <p style={{position: 'absolute', top: '127px', left: '36px', whiteSpace: 'nowrap'}} className="ft11">•&nbsp;<b>Do not send this form to the Canada Revenue Agency (CRA) unless we ask for it. We will not keep or return this form.</b></p>
          <p style={{position: 'absolute', top: '154px', left: '36px', whiteSpace: 'nowrap'}} className="ft11">•&nbsp;Complete this return for every initial and amended T2 Corporation Income Tax Return electronically filed with the CRA on your&nbsp;behalf.</p>
          <p style={{position: 'absolute', top: '176px', left: '36px', whiteSpace: 'nowrap'}} className="ft11">•&nbsp;By completing Part 2 and signing Part 3, you acknowledge that, under the federal Income Tax Act, you have to keep all records&nbsp;used to prepare&nbsp;</p>
          <p style={{position: 'absolute', top: '190px', left: '45px', whiteSpace: 'nowrap'}} className="ft11">your T2 Corporation Income Tax Return, and provide this information to us on request.</p>
          <p style={{position: 'absolute', top: '217px', left: '36px', whiteSpace: 'nowrap'}} className="ft11">•&nbsp;Part 4 must be completed by either you or the electronic transmitter of your T2 Corporation Income Tax Return.&nbsp;</p>
          <p style={{position: 'absolute', top: '244px', left: '36px', whiteSpace: 'nowrap'}} className="ft11">•&nbsp;Give the signed original of this return to the transmitter and keep a copy in your own records for six years.</p>
          <p style={{position: 'absolute', top: '271px', left: '36px', whiteSpace: 'nowrap'}} className="ft11">•&nbsp;We are responsible for ensuring the confidentiality of your electronically filed tax information only after we have accepted your return.</p>
          <p style={{position: 'absolute', top: '322px', left: '62px', whiteSpace: 'nowrap'}} className="ft14"><b>Part 1&nbsp;</b>–<b>&nbsp;Identification</b></p>
          <p style={{position: 'absolute', top: '350px', left: '36px', whiteSpace: 'nowrap'}} className="ft11">Corporation's name
            <br />
            <input type="text" className="borderless-input" style={{width:'100px'}} defaultValue={state.corp_name} />
          </p>
          <p style={{position: 'absolute', top: '352px', left: '621px', whiteSpace: 'nowrap'}} className="ft11">Business number
          <br />
          &nbsp; &nbsp; &nbsp; &nbsp; <input type="text"  className="borderless-input2" style={{width:'180px'}} defaultValue={state.bus_number} />
          </p>
          <p style={{position: 'absolute', top: '409px', left: '55px', whiteSpace: 'nowrap'}} className="ft11">Tax</p>
          <p style={{position: 'absolute', top: '424px', left: '41px', whiteSpace: 'nowrap'}} className="ft11">year start</p>
          <p style={{position: 'absolute', top: '400px', left: '103px', whiteSpace: 'nowrap'}} className="ft11">&nbsp; &nbsp; &nbsp; Year &nbsp; &nbsp; &nbsp; Month &nbsp; Day
          <br></br>
          &nbsp; &nbsp; &nbsp; &nbsp; <input type="text" className="borderless-input2" style={{width:'100px'}} defaultValue={state.tax_start} />
          </p>
          <p style={{position: 'absolute', top: '409px', left: '267px', whiteSpace: 'nowrap'}} className="ft11">Tax</p>
          <p style={{position: 'absolute', top: '424px', left: '253px', whiteSpace: 'nowrap'}} className="ft11">year-end</p>
          <p style={{position: 'absolute', top: '400px', left: '315px', whiteSpace: 'nowrap'}} className="ft11">&nbsp; &nbsp; &nbsp; Year &nbsp; &nbsp; &nbsp; Month &nbsp; Day
          <br></br>
          &nbsp; &nbsp; &nbsp; &nbsp; <input type="text" className="borderless-input2" style={{width:'100px'}} defaultValue={state.tax_end} />
          </p>
          <p style={{position: 'absolute', top: '410px', left: '517px', whiteSpace: 'nowrap'}} className="ft11">Is this an amended return?&nbsp;.&nbsp;.&nbsp;.&nbsp;Yes;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>&nbsp;
          </p>
          <p style={{position: 'absolute', top: '414px', left: '759px', whiteSpace: 'nowrap'}} className="ft11">
         
          <i class="fas fa-envelope-open-tex"></i> 
            Yes</p>
          <p style={{position: 'absolute', top: '414px', left: '840px', whiteSpace: 'nowrap'}} className="ft11">No</p>
          <p style={{position: 'absolute', top: '466px', left: '41px', whiteSpace: 'nowrap'}} className="ft13"><b>Get your CRA mail electronically delivered in My Business Account at canada.ca/my-cra-business-account&nbsp;</b>(optional)</p>
          <p style={{position: 'absolute', top: '496px', left: '41px', whiteSpace: 'nowrap'}} className="ft13"><b>Email address:</b></p>
          <p style={{position: 'absolute', top: '527px', left: '41px', whiteSpace: 'nowrap'}} className="ft17">I understand that by providing an email address, I am&nbsp;<b>registering</b>&nbsp;the corporation to receive email notifications from the CRA. I understand and agree that all&nbsp;<br />notices and other correspondence eligible for electronic delivery will no longer be printed and mailed. The CRA will notify the&nbsp;corporation at this email address&nbsp;<br />when they are available in My Business Account and requiring immediate attention. They will be presumed to have been received on the date that the email&nbsp;<br />is sent. For more information, see&nbsp;<b>canada.ca/cra-business-email-notifications</b>.</p>
          <p style={{position: 'absolute', top: '659px', left: '62px', whiteSpace: 'nowrap'}} className="ft14"><b>Part 2&nbsp;</b>–<b>&nbsp;Declaration</b></p>
          <p style={{position: 'absolute', top: '694px', left: '41px', whiteSpace: 'nowrap'}} className="ft11">Enter the following amounts, if applicable, from the T2 return for the tax year noted above:</p>
          <p style={{position: 'absolute', top: '719px', left: '63px', whiteSpace: 'nowrap'}} className="ft18">Net income or loss for income tax purposes from Schedule 1, financial statements, or&nbsp;<br />General Index of Financial Information (GIFI) (line 300)&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '759px', left: '63px', whiteSpace: 'nowrap'}} className="ft11">Part I tax payable (line 700)&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '786px', left: '63px', whiteSpace: 'nowrap'}} className="ft11">Part III.1 tax payable (line 710)&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '813px', left: '63px', whiteSpace: 'nowrap'}} className="ft11">Part IV tax payable (line 712)&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '840px', left: '63px', whiteSpace: 'nowrap'}} className="ft11">Part IV.1 tax payable (line 716)&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '867px', left: '63px', whiteSpace: 'nowrap'}} className="ft11">Part VI tax payable (line 720)&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '894px', left: '63px', whiteSpace: 'nowrap'}} className="ft11">Part VI.1 tax payable (line 724)&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '921px', left: '63px', whiteSpace: 'nowrap'}} className="ft11">Part XIV tax payable (line 728)&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '948px', left: '63px', whiteSpace: 'nowrap'}} className="ft11">Net provincial and territorial tax payable (line 760)&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1147px', left: '31px', whiteSpace: 'nowrap'}} className="ft16">T183 CORP E (21)</p>
          <p style={{position: 'absolute', top: '1147px', left: '362px', whiteSpace: 'nowrap'}} className="ft16">(Ce formulaire est disponible en français.)</p>
          <p style={{position: 'absolute', top: '1146px', left: '647px', whiteSpace: 'nowrap'}} className="ft16">Page 1 of 2</p>
          <p style={{position: 'absolute', top: '373px', left: '784px', whiteSpace: 'nowrap'}} className="ft13"><b>R&nbsp;C</b></p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\tp {margin: 0; padding: 0;}\t.ft20{font-size:11px;font-family:Helvetica;color:#000000;}\n\t.ft21{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft22{font-size:13px;font-family:Helvetica;color:#000000;}\n\t.ft23{font-size:13px;font-family:Helvetica;color:#000000;}\n\t.ft24{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft25{font-size:8px;font-family:Helvetica;color:#000000;}\n\t.ft26{font-size:10px;line-height:14px;font-family:Helvetica;color:#000000;}\n" }} />
        <div id="page2-div" style={{position: 'relative', width: '918px', height: '1188px'}}>
          <img width={918} height={1188} src="target002.png" alt="background image" />
          <p style={{position: 'absolute', top: '34px', left: '718px', whiteSpace: 'nowrap'}} className="ft20"><b>Protected B&nbsp;</b></p>
          <p style={{position: 'absolute', top: '35px', left: '797px', whiteSpace: 'nowrap'}} className="ft21">when completed</p>
          <p style={{position: 'absolute', top: '83px', left: '62px', whiteSpace: 'nowrap'}} className="ft22"><b>Part 3&nbsp;</b>–<b>&nbsp;Certification and authorization</b></p>
          <p style={{position: 'absolute', top: '110px', left: '41px', whiteSpace: 'nowrap'}} className="ft21">I,
          &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;<input style={{marginBottom:'30px'}} type="text"  className="borderless-input" defaultValue={state.last_name} />
           &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;  &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; <input style={{marginBottom:'30px'}} type="text"  className="borderless-input" defaultValue={state.first_name} />
            &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; <input style={{marginBottom:'30px', width:'140px'}} type="text"  className="borderless-input" defaultValue={state.position} />
           </p>
          <p style={{position: 'absolute', top: '133px', left: '170px', whiteSpace: 'nowrap'}} className="ft21">Last name</p>
          <p style={{position: 'absolute', top: '133px', left: '467px', whiteSpace: 'nowrap'}} className="ft21">First name
           </p>
          <p style={{position: 'absolute', top: '133px', left: '704px', whiteSpace: 'nowrap'}} className="ft21">Position, office, or rank
            </p>
          <p style={{position: 'absolute', top: '120px', left: '880px', whiteSpace: 'nowrap'}} className="ft21">,</p>
          <p style={{position: 'absolute', top: '163px', left: '41px', whiteSpace: 'nowrap'}} className="ft26">am an authorized signing officer of the corporation. I certify that I have examined the T2 Corporation Income Tax Return, including accompanying schedules&nbsp;<br />and statements, and that the information given on the T2 return and this T183 Corp information return is, to the best of my knowledge, correct and complete.<br />I also certify that the method of calculating income for this tax year is consistent with that of the previous tax year except&nbsp;as specifically disclosed in a&nbsp;<br />statement attached to this return.</p>
          <p style={{position: 'absolute', top: '226px', left: '41px', whiteSpace: 'nowrap'}} className="ft26">I authorize the transmitter identified in Part 4 to electronically file the T2 Corporation Income Tax Return identified in Part&nbsp;1. The transmitter can also modify&nbsp;<br />the information originally filed in response to any errors Canada Revenue Agency identifies. This authorization expires when the Minister of National Revenue&nbsp;<br />accepts the electronic return as filed.</p>
         
          <p style={{position: 'absolute', top: '285px', left: '67px', whiteSpace: 'nowrap'}} className="ft21">
          &nbsp;<input style={{marginBottom:'30px'}} type="text"  className="borderless-input" defaultValue={currenDate} />
          &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;  &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; <input style={{marginBottom:'30px'}} type="text"  className="borderless-input" defaultValue={`${state.first_name} ${state.last_name}`} />
          &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;  &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  <input style={{marginBottom:'30px',width:'100px'}} type="text"  className="borderless-input" defaultValue={state.phone}/>
          
          </p>
          <p style={{position: 'absolute', top: '310px', left: '67px', whiteSpace: 'nowrap'}} className="ft21">
            Date (yyyy/mm/dd)</p>
          <p style={{position: 'absolute', top: '309px', left: '286px', whiteSpace: 'nowrap'}} className="ft21">Signature of an authorized signing officer of the corporation</p>
          <p style={{position: 'absolute', top: '310px', left: '735px', whiteSpace: 'nowrap'}} className="ft21">Telephone number</p>
          <p style={{position: 'absolute', top: '340px', left: '41px', whiteSpace: 'nowrap'}} className="ft21">The CRA will accept an electronic signature if it is applied in accordance with the guidance specified by the CRA.</p>
          <p style={{position: 'absolute', top: '430px', left: '62px', whiteSpace: 'nowrap'}} className="ft22"><b>Part 4&nbsp;</b>–<b>&nbsp;Transmitter identification</b></p>
          <p style={{position: 'absolute', top: '462px', left: '41px', whiteSpace: 'nowrap'}} className="ft21">The following transmitter has electronically filed the tax return of the corporation identified in Part 1.</p>
          <p style={{position: 'absolute', top: '507px', left: '249px', whiteSpace: 'nowrap'}} className="ft21">Name of person or firm</p>
          <p style={{position: 'absolute', top: '507px', left: '678px', whiteSpace: 'nowrap'}} className="ft21">Electronic filer number</p>
          <p style={{position: 'absolute', top: '596px', left: '62px', whiteSpace: 'nowrap'}} className="ft22"><b>Privacy notice</b></p>
          <p style={{position: 'absolute', top: '624px', left: '41px', whiteSpace: 'nowrap'}} className="ft26">Personal information is collected for the purposes of the administration or enforcement of the Income Tax Act and related programs and activities including&nbsp;<br />administering tax, benefits, audit, compliance, and collection. The information collected may be used or disclosed for purposes&nbsp;of other federal acts that&nbsp;<br />provide for the imposition and collection of a tax or duty. It may also be disclosed to other federal, provincial, territorial,&nbsp;or foreign government institutions to&nbsp;<br />the extent authorized by law. Failure to provide this information may result in interest payable, penalties, or other actions.&nbsp;Under the Privacy Act, individuals&nbsp;<br />have a right of protection, access to and correction of their personal information, or to file a complaint with the Privacy Commissioner of Canada regarding the&nbsp;<br />handling of their personal information. Refer to Personal Information Bank CRA PPU 047 and CRA PPU 211 on Info Source at&nbsp;<b>canada.ca/cra-info-source</b>.</p>
          <p style={{position: 'absolute', top: '1146px', left: '823px', whiteSpace: 'nowrap'}} className="ft25">Page 2 of 2</p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\tp {margin: 0; padding: 0;}\t.ft30{font-size:10px;font-family:Helvetica;color:#ffffff;}\n\t.ft31{font-size:11px;font-family:Helvetica;color:#000000;}\n\t.ft32{font-size:11px;font-family:Helvetica;color:#000000;}\n\t.ft33{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft34{font-size:16px;font-family:Helvetica;color:#000000;}\n\t.ft35{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft36{font-size:13px;font-family:Helvetica;color:#000000;}\n\t.ft37{font-size:8px;font-family:Helvetica;color:#000000;}\n\t.ft38{font-size:10px;line-height:13px;font-family:Helvetica;color:#000000;}\n\t.ft39{font-size:10px;line-height:13px;font-family:Helvetica;color:#000000;}\n" }} />
        <div id="page3-div" style={{position: 'relative', width: '918px', height: '1188px'}}>
          <img width={918} height={1188} src="target003.png" alt="background image" />
          <p style={{position: 'absolute', top: '36px', left: '863px', whiteSpace: 'nowrap'}} className="ft30"><b>200</b></p>
          <p style={{position: 'absolute', top: '56px', left: '820px', whiteSpace: 'nowrap'}} className="ft31">Code 2201</p>
          <p style={{position: 'absolute', top: '73px', left: '811px', whiteSpace: 'nowrap'}} className="ft32"><b>Protected B&nbsp;</b></p>
          <p style={{position: 'absolute', top: '86px', left: '799px', whiteSpace: 'nowrap'}} className="ft33">when completed</p>
          <p style={{position: 'absolute', top: '58px', left: '311px', whiteSpace: 'nowrap'}} className="ft34"><b>T2 Corporation Income Tax Return&nbsp;</b></p>
          <p style={{position: 'absolute', top: '77px', left: '351px', whiteSpace: 'nowrap'}} className="ft34"><b>(2022 and later tax years)</b></p>
          <p style={{position: 'absolute', top: '104px', left: '36px', whiteSpace: 'nowrap'}} className="ft38">This form serves as a federal, provincial, and territorial corporation income tax return, unless the corporation is located in&nbsp;<br />Quebec or Alberta. If the corporation is located in one of these provinces, you have to file a separate provincial&nbsp;<br />corporation return. A shorter version of the return, the T2SHORT, is available for eligible corporations.</p>
          <p style={{position: 'absolute', top: '149px', left: '36px', whiteSpace: 'nowrap'}} className="ft38">All legislative references on this return are to the federal Income Tax Act and Income Tax Regulations. This return may&nbsp;<br />contain changes that had not yet become law at the time of publication.</p>
          <p style={{position: 'absolute', top: '181px', left: '36px', whiteSpace: 'nowrap'}} className="ft38">Send one completed copy of this return, including schedules and the General Index of Financial Information (GIFI), to your&nbsp;<br />tax centre. You have to file the return within six months after the end of the corporation's tax year.</p>
          <p style={{position: 'absolute', top: '216px', left: '36px', whiteSpace: 'nowrap'}} className="ft33">For more information see&nbsp;<b>canada.ca/taxes</b>&nbsp;or Guide T4012, T2 Corporation – Income Tax Guide.</p>
          <p style={{position: 'absolute', top: '117px', left: '705px', whiteSpace: 'nowrap'}} className="ft30"><b>055</b></p>
          <p style={{position: 'absolute', top: '117px', left: '743px', whiteSpace: 'nowrap'}} className="ft35"><b>Do not use this area</b></p>
          <p style={{position: 'absolute', top: '240px', left: '64px', whiteSpace: 'nowrap'}} className="ft36"><b>Identification</b></p>
          <p style={{position: 'absolute', top: '250px', left: '363px', whiteSpace: 'nowrap'}} className="ft30"><b>001</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="text" className="borderless-input2" style={{width:'180px'}} defaultValue={state.bus_number.split('').join(' ')}/>
          </p>
          
          <p style={{position: 'absolute', top: '262px', left: '36px', whiteSpace: 'nowrap'}} className="ft35"><b>Business number (BN)&nbsp;</b>.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.
          </p>
          <p style={{position: 'absolute', top: '302px', left: '39px', whiteSpace: 'nowrap'}} className="ft30"><b>002</b></p>
          <p style={{position: 'absolute', top: '280px', left: '36px', whiteSpace: 'nowrap'}} className="ft35"><b>Corporation's name</b>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="text" className="borderless-input" defaultValue={state.corp_name}/>
          
          </p>
          <p style={{position: 'absolute', top: '369px', left: '246px', whiteSpace: 'nowrap'}} className="ft30"><b>010</b></p>
          <p style={{position: 'absolute', top: '343px', left: '36px', whiteSpace: 'nowrap'}} className="ft35"><b>Address of head office</b></p>
          <p style={{position: 'absolute', top: '360px', left: '36px', whiteSpace: 'nowrap'}} className="ft38">Has this address changed since the&nbsp;<br />last time the CRA was notified?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '369px', left: '288px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '369px', left: '373px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;No</p>
          <p style={{position: 'absolute', top: '388px', left: '36px', whiteSpace: 'nowrap'}} className="ft33">If&nbsp;<b>yes</b>, complete lines 011 to 018.</p>
          <p style={{position: 'absolute', top: '402px', left: '39px', whiteSpace: 'nowrap'}} className="ft30"><b>011</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  type="text" className="borderless-input" style={{width:'200px'}}  defaultValue={state.address}/>
          
          </p>
          <p style={{position: 'absolute', top: '428px', left: '39px', whiteSpace: 'nowrap'}} className="ft30"><b>012</b></p>
          <p style={{position: 'absolute', top: '455px', left: '39px', whiteSpace: 'nowrap'}} className="ft30"><b>015</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  type="text" className="borderless-input" style={{width:'50px'}}  defaultValue={state.city}/>
          
          </p>
          <p style={{position: 'absolute', top: '446px', left: '72px', whiteSpace: 'nowrap'}} className="ft33">City</p>
          <p style={{position: 'absolute', top: '457px', left: '246px', whiteSpace: 'nowrap'}} className="ft30"><b>016</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  type="text" className="borderless-input" style={{width:'50px'}}  defaultValue={state.province}/>

          </p>
          <p style={{position: 'absolute', top: '446px', left: '283px', whiteSpace: 'nowrap'}} className="ft33">Province, territory, or state</p>
          <p style={{position: 'absolute', top: '500px', left: '39px', whiteSpace: 'nowrap'}} className="ft30"><b>017</b></p>
          <p style={{position: 'absolute', top: '482px', left: '72px', whiteSpace: 'nowrap'}} className="ft33">Country (other than Canada)
          {/* &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  type="text" className="borderless-input" style={{width:'50px'}}  defaultValue={state.city}/> */}
          </p>
          <p style={{position: 'absolute', top: '493px', left: '246px', whiteSpace: 'nowrap'}} className="ft30"><b>018</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  type="text" className="borderless-input" style={{width:'50px'}}  defaultValue={state.zip}/>

          </p>
          <p style={{position: 'absolute', top: '482px', left: '283px', whiteSpace: 'nowrap'}} className="ft33">Postal or ZIP code</p>
          <p style={{position: 'absolute', top: '549px', left: '246px', whiteSpace: 'nowrap'}} className="ft30"><b>020</b></p>
          <p style={{position: 'absolute', top: '523px', left: '36px', whiteSpace: 'nowrap'}} className="ft35"><b>Mailing address</b>&nbsp;(if different from head office address)</p>
          <p style={{position: 'absolute', top: '540px', left: '36px', whiteSpace: 'nowrap'}} className="ft38">Has this address changed since the&nbsp;<br />last time the CRA was notified?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '549px', left: '288px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '549px', left: '373px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;No</p>
          <p style={{position: 'absolute', top: '568px', left: '36px', whiteSpace: 'nowrap'}} className="ft33">If&nbsp;<b>yes</b>, complete lines 021 to 028.</p>
          <p style={{position: 'absolute', top: '582px', left: '39px', whiteSpace: 'nowrap'}} className="ft30"><b>021</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="text" style={{width:'200px'}}  className="borderless-input" defaultValue={state.mail_address}/>
          
          </p>
          <p style={{position: 'absolute', top: '590px', left: '72px', whiteSpace: 'nowrap'}} className="ft33">c/o</p>
          <p style={{position: 'absolute', top: '608px', left: '39px', whiteSpace: 'nowrap'}} className="ft30"><b>022</b></p>
          <p style={{position: 'absolute', top: '626px', left: '39px', whiteSpace: 'nowrap'}} className="ft30"><b>023</b></p>
          <p style={{position: 'absolute', top: '655px', left: '39px', whiteSpace: 'nowrap'}} className="ft30"><b>025</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="text" style={{width:'100px'}}  className="borderless-input" defaultValue={state.mail_city}/>
          </p>
          <p style={{position: 'absolute', top: '644px', left: '72px', whiteSpace: 'nowrap'}} className="ft33">City</p>
          <p style={{position: 'absolute', top: '655px', left: '246px', whiteSpace: 'nowrap'}} className="ft30"><b>026</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="text" style={{width:'100px'}}  className="borderless-input" defaultValue={state.mail_province}/>
          </p>
          <p style={{position: 'absolute', top: '644px', left: '283px', whiteSpace: 'nowrap'}} className="ft33">Province, territory, or state</p>
          <p style={{position: 'absolute', top: '698px', left: '39px', whiteSpace: 'nowrap'}} className="ft30"><b>027</b></p>
          <p style={{position: 'absolute', top: '680px', left: '72px', whiteSpace: 'nowrap'}} className="ft33">Country (other than Canada)</p>
          <p style={{position: 'absolute', top: '691px', left: '246px', whiteSpace: 'nowrap'}} className="ft30"><b>028</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="text" style={{width:'100px'}}  className="borderless-input" defaultValue={state.mail_zip}/>
          </p>
          <p style={{position: 'absolute', top: '680px', left: '283px', whiteSpace: 'nowrap'}} className="ft33">Postal or ZIP code</p>
          <p style={{position: 'absolute', top: '747px', left: '246px', whiteSpace: 'nowrap'}} className="ft30"><b>030</b></p>
          <p style={{position: 'absolute', top: '721px', left: '36px', whiteSpace: 'nowrap'}} className="ft35"><b>Location of books and records</b>&nbsp;(if different from head office address)</p>
          <p style={{position: 'absolute', top: '738px', left: '36px', whiteSpace: 'nowrap'}} className="ft38">Has this address changed since the&nbsp;<br />last time the CRA was notified?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '747px', left: '288px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '747px', left: '369px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp; No</p>
          <p style={{position: 'absolute', top: '775px', left: '36px', whiteSpace: 'nowrap'}} className="ft33">If&nbsp;<b>yes</b>, complete lines 031 to 038.</p>
          <p style={{position: 'absolute', top: '785px', left: '39px', whiteSpace: 'nowrap'}} className="ft30"><b>031</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="text" style={{width:'300px'}}  className="borderless-input" defaultValue={state.book_address}/>
          </p>
          <p style={{position: 'absolute', top: '810px', left: '39px', whiteSpace: 'nowrap'}} className="ft30"><b>032</b></p>
          <p style={{position: 'absolute', top: '837px', left: '39px', whiteSpace: 'nowrap'}} className="ft30"><b>035</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="text" style={{width:'80px'}}  className="borderless-input" defaultValue={state.book_city}/>
          
          </p>
          <p style={{position: 'absolute', top: '829px', left: '72px', whiteSpace: 'nowrap'}} className="ft33">City</p>
          <p style={{position: 'absolute', top: '837px', left: '246px', whiteSpace: 'nowrap'}} className="ft30"><b>036</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="text" style={{width:'80px'}} className="borderless-input" defaultValue={state.book_province}/>
          </p>
          <p style={{position: 'absolute', top: '828px', left: '283px', whiteSpace: 'nowrap'}} className="ft33">Province, territory, or state</p>
          <p style={{position: 'absolute', top: '882px', left: '39px', whiteSpace: 'nowrap'}} className="ft30"><b>037</b></p>
          <p style={{position: 'absolute', top: '865px', left: '72px', whiteSpace: 'nowrap'}} className="ft33">Country (other than Canada)</p>
          <p style={{position: 'absolute', top: '875px', left: '246px', whiteSpace: 'nowrap'}} className="ft30"><b>038</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="text" style={{width:'80px'}} className="borderless-input" defaultValue={state.book_zip}/>
          </p>
          <p style={{position: 'absolute', top: '864px', left: '283px', whiteSpace: 'nowrap'}} className="ft33">Postal or ZIP code</p>
          <p style={{position: 'absolute', top: '905px', left: '39px', whiteSpace: 'nowrap'}} className="ft30"><b>040</b></p>
          <p style={{position: 'absolute', top: '905px', left: '72px', whiteSpace: 'nowrap'}} className="ft35"><b>Type of corporation at the end of the tax year</b>&nbsp;(tick one)</p>
          {state.corp_type == 'Canadian-controlled private corporation (CCPC)' ?
           <p style={{position: 'absolute', top: '926px', left: '52px', whiteSpace: 'nowrap'}} className="ft33">
         <input style={{ width: '15px' }} type="text" className="borderless-input" defaultValue="X" /> 1 Canadian-controlled private corporation (CCPC)</p>
          : <p style={{position: 'absolute', top: '926px', left: '52px', whiteSpace: 'nowrap'}} className="ft33"> <input style={{ width: '15px' }} type="text" className="borderless-input" defaultValue="" /> 1 Canadian-controlled private corporation (CCPC)</p>
          }

          {state.corp_type == 'Other private corporation' ?
          <p style={{position: 'absolute', top: '948px', left: '52px', whiteSpace: 'nowrap'}} className="ft33">
          <input style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>&nbsp;
            2 Other private corporation</p> : 
            <p style={{position: 'absolute', top: '948px', left: '52px', whiteSpace: 'nowrap'}} className="ft33">
            <input style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>&nbsp;
              2 Other private corporation</p> }
          {state.corp_type == 'Public corporation' ?
          <p style={{position: 'absolute', top: '972px', left: '52px', whiteSpace: 'nowrap'}} className="ft33">
          <input style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>&nbsp;
            3 Public corporation</p>:
             <p style={{position: 'absolute', top: '972px', left: '52px', whiteSpace: 'nowrap'}} className="ft33">
             <input style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>&nbsp;
               3 Public corporation</p>}
          {state.corp_type == 'Corporation controlled by a public corporation' ?

          <p style={{position: 'absolute', top: '994px', left: '52px', whiteSpace: 'nowrap'}} className="ft33">
          <input style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>&nbsp;
            4 Corporation controlled by a public corporation</p>:
            <p style={{position: 'absolute', top: '994px', left: '52px', whiteSpace: 'nowrap'}} className="ft33">
            <input style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>&nbsp;
              4 Corporation controlled by a public corporation</p>}

          {state.corp_type == 'Other corporation (specify)' ?
          <p style={{position: 'absolute', top: '1017px', left: '52px', whiteSpace: 'nowrap'}} className="ft33">
          <input style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>&nbsp;
            5 Other corporation (specify)</p>:
            <p style={{position: 'absolute', top: '1017px', left: '52px', whiteSpace: 'nowrap'}} className="ft33">
            <input style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>&nbsp;
              5 Other corporation (specify)</p>}
          <p style={{position: 'absolute', top: '1060px', left: '246px', whiteSpace: 'nowrap'}} className="ft30"><b>043</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="text" style={{width:'80px'}} className="borderless-input" defaultValue={state.corp_date}/>
          </p>
          <p style={{position: 'absolute', top: '1045px', left: '41px', whiteSpace: 'nowrap'}} className="ft38">If the type of corporation changed&nbsp;<br />during the tax year, provide the&nbsp;<br />effective date of the change&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1049px', left: '295px', whiteSpace: 'nowrap'}} className="ft33">Year &nbsp;Month &nbsp;Day</p>
          <p style={{position: 'absolute', top: '284px', left: '454px', whiteSpace: 'nowrap'}} className="ft35"><b>To which tax year does this return apply?</b></p>
          <p style={{position: 'absolute', top: '330px', left: '453px', whiteSpace: 'nowrap'}} className="ft30"><b>060</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input type="text" style={{width:'90px'}}  className="borderless-input" defaultValue={state.tax_start}/>

          </p>
          <p style={{position: 'absolute', top: '302px', left: '530px', whiteSpace: 'nowrap'}} className="ft33">Tax year start</p>
          <p style={{position: 'absolute', top: '320px', left: '520px', whiteSpace: 'nowrap'}} className="ft33">Year &nbsp;Month &nbsp;Day</p>
          <p style={{position: 'absolute', top: '330px', left: '696px', whiteSpace: 'nowrap'}} className="ft30"><b>061</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'90px'}} type="text" className="borderless-input2" defaultValue={state.tax_end}/>
          
          </p>
          <p style={{position: 'absolute', top: '302px', left: '766px', whiteSpace: 'nowrap'}} className="ft33">Tax year-end</p>
          <p style={{position: 'absolute', top: '320px', left: '754px', whiteSpace: 'nowrap'}} className="ft33">Year &nbsp;Month &nbsp;Day</p>
          {state.aquisition == 'YES' ?
          <p style={{position: 'absolute', top: '395px', left: '695px', whiteSpace: 'nowrap'}} className="ft30"><b>063</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'14px'}} type="text" className="borderless-input" defaultValue='X'/>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
          </p>:  <p style={{position: 'absolute', top: '395px', left: '696px', whiteSpace: 'nowrap'}} className="ft30"><b>063</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
          </p>}
          <p style={{position: 'absolute', top: '365px', left: '450px', whiteSpace: 'nowrap'}} className="ft39"><b>Has there been an acquisition of control&nbsp;<br />resulting in the application of&nbsp;<br />subsection 249(4) since the tax year&nbsp;<br />start on line 060?&nbsp;&nbsp;</b>.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '400px', left: '738px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;Yes
          </p>
          <p style={{position: 'absolute', top: '400px', left: '819px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;No</p>
          <p style={{position: 'absolute', top: '433px', left: '698px', whiteSpace: 'nowrap'}} className="ft30"><b>065</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'94px'}} type="text" className="borderless-input" defaultValue={state.aquisition_date}/>
          </p>
          <p style={{position: 'absolute', top: '433px', left: '450px', whiteSpace: 'nowrap'}} className="ft38">If&nbsp;<b>yes</b>, provide the date control was&nbsp;<br />acquired&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '423px', left: '754px', whiteSpace: 'nowrap'}} className="ft33">Year &nbsp;Month &nbsp;Day</p>
          {state.tax_year_date == 'YES' ?
          <p style={{position: 'absolute', top: '486px', left: '696px', whiteSpace: 'nowrap'}} className="ft30"><b>066</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
          </p>: <p style={{position: 'absolute', top: '486px', left: '696px', whiteSpace: 'nowrap'}} className="ft30"><b>066</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" />
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
          </p>}
          <p style={{position: 'absolute', top: '469px', left: '450px', whiteSpace: 'nowrap'}} className="ft39"><b>Is the date on line 061 a deemed&nbsp;<br />tax year-end according to&nbsp;<br />subsection 249(3.1)?&nbsp;&nbsp;</b>.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '490px', left: '738px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '490px', left: '819px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;No</p>
          {state.professional_corp == "YES" ?
          <p style={{position: 'absolute', top: '536px', left: '695px', whiteSpace: 'nowrap'}} className="ft30"><b>067</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
          </p>:
           <p style={{position: 'absolute', top: '536px', left: '696px', whiteSpace: 'nowrap'}} className="ft30"><b>067</b>
           &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
           &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
           </p>}
          <p style={{position: 'absolute', top: '518px', left: '450px', whiteSpace: 'nowrap'}} className="ft39"><b>Is the corporation a professional&nbsp;<br />corporation that is a member of a&nbsp;<br />partnership?&nbsp;</b>.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '540px', left: '738px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '540px', left: '819px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;No</p>
          <p style={{position: 'absolute', top: '568px', left: '450px', whiteSpace: 'nowrap'}} className="ft35"><b>Is this the first year of filing after:</b></p>
          {state.first_filling == "YES"?
         <p style={{position: 'absolute', top: '575px', left: '695px', whiteSpace: 'nowrap'}} className="ft30"><b>070</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
           &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
          </p> :
          <p style={{position: 'absolute', top: '575px', left: '695px', whiteSpace: 'nowrap'}} className="ft30"><b>070</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
           &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
          </p>}
          <p style={{position: 'absolute', top: '585px', left: '459px', whiteSpace: 'nowrap'}} className="ft33">Incorporation?&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '580px', left: '738px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '580px', left: '819px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;No</p>
          {state.first_amalgamation == "YES"?
          <p style={{position: 'absolute', top: '595px', left: '695px', whiteSpace: 'nowrap'}} className="ft30"><b>071</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
           &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
          </p> :
          <p style={{position: 'absolute', top: '595px', left: '697px', whiteSpace: 'nowrap'}} className="ft30"><b>071</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
           &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
          </p>}
          <p style={{position: 'absolute', top: '604px', left: '459px', whiteSpace: 'nowrap'}} className="ft33">Amalgamation?&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '598px', left: '738px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '598px', left: '819px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;No</p>
          <p style={{position: 'absolute', top: '622px', left: '450px', whiteSpace: 'nowrap'}} className="ft33">If&nbsp;<b>yes</b>, complete lines 030 to 038 and attach Schedule 24.</p>
          {state.wind_up == "YES"?
          <p style={{position: 'absolute', top: '662px', left: '695px', whiteSpace: 'nowrap'}} className="ft30"><b>072</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
           &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
          </p>:
          <p style={{position: 'absolute', top: '662px', left: '697px', whiteSpace: 'nowrap'}} className="ft30"><b>072</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
           &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
          </p>}
          <p style={{position: 'absolute', top: '644px', left: '450px', whiteSpace: 'nowrap'}} className="ft39"><b>Has there been a wind-up of a&nbsp;<br />subsidiary under section 88 during the&nbsp;<br />current tax year?&nbsp;</b>.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '666px', left: '738px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '666px', left: '819px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;No</p>
          <p style={{position: 'absolute', top: '689px', left: '450px', whiteSpace: 'nowrap'}} className="ft33">If&nbsp;<b>yes</b>, complete and attach Schedule 24.</p>
          {state.final_tax == "YES"?
          <p style={{position: 'absolute', top: '716px', left: '695px', whiteSpace: 'nowrap'}} className="ft30"><b>076</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
           &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
          </p>:
          <p style={{position: 'absolute', top: '717px', left: '695px', whiteSpace: 'nowrap'}} className="ft30"><b>076</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
           &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
          </p>}
          <p style={{position: 'absolute', top: '712px', left: '450px', whiteSpace: 'nowrap'}} className="ft39"><b>Is this the final tax year before&nbsp;<br />amalgamation?&nbsp;</b>.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '720px', left: '738px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '720px', left: '819px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;No</p>
          {state.final_return == "YES"?
          <p style={{position: 'absolute', top: '751px', left: '695px', whiteSpace: 'nowrap'}} className="ft30"><b>078</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
           &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
          </p>:
          <p style={{position: 'absolute', top: '751px', left: '697px', whiteSpace: 'nowrap'}} className="ft30"><b>078</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
           &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
          </p>
        }
          <p style={{position: 'absolute', top: '748px', left: '450px', whiteSpace: 'nowrap'}} className="ft39"><b>Is this the final return up to&nbsp;<br />dissolution?&nbsp;</b>.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '756px', left: '738px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '756px', left: '819px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;No</p>
          <p style={{position: 'absolute', top: '799px', left: '696px', whiteSpace: 'nowrap'}} className="ft30"><b>079</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'50px'}} type="text" className="borderless-input" defaultValue={state.election_currency}/>
          </p>
          <p style={{position: 'absolute', top: '783px', left: '450px', whiteSpace: 'nowrap'}} className="ft39"><b>If an election was made under&nbsp;<br />section 261, state the functional&nbsp;<br />currency used&nbsp;&nbsp;</b>.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          {state.resident == "YES"?
          <p style={{position: 'absolute', top: '837px', left: '695px', whiteSpace: 'nowrap'}} className="ft30"><b>080</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
          </p>:
          <p style={{position: 'absolute', top: '837px', left: '696px', whiteSpace: 'nowrap'}} className="ft30"><b>080</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
          </p>
          }
          <p style={{position: 'absolute', top: '842px', left: '450px', whiteSpace: 'nowrap'}} className="ft35"><b>Is the corporation a resident of Canada?</b></p>
          <p style={{position: 'absolute', top: '841px', left: '738px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '841px', left: '819px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;No</p>
          <p style={{position: 'absolute', top: '893px', left: '453px', whiteSpace: 'nowrap'}} className="ft30"><b>081</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'150px'}} type="text" className="borderless-input" defaultValue={state.residence_country}/>
          </p>
          <p style={{position: 'absolute', top: '865px', left: '450px', whiteSpace: 'nowrap'}} className="ft38">If&nbsp;<b>no</b>, give the country of residence on line 081 and complete and attach&nbsp;<br />Schedule 97.</p>
          {state.exemption == "YES"?
          <p style={{position: 'absolute', top: '940px', left: '695px', whiteSpace: 'nowrap'}} className="ft30"><b>082</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='X'/>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
          </p>:
          <p style={{position: 'absolute', top: '940px', left: '695px', whiteSpace: 'nowrap'}} className="ft30"><b>082</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue=' X'/>
          </p>}

          <p style={{position: 'absolute', top: '923px', left: '450px', whiteSpace: 'nowrap'}} className="ft39"><b>Is the non-resident corporation claiming&nbsp;<br />an exemption under an income tax&nbsp;<br />treaty?&nbsp;</b>.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '945px', left: '738px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '945px', left: '819px', whiteSpace: 'nowrap'}} className="ft33">&nbsp; &nbsp;No</p>
          <p style={{position: 'absolute', top: '964px', left: '450px', whiteSpace: 'nowrap'}} className="ft33">If&nbsp;<b>yes</b>, complete and attach Schedule 91.</p>
          <p style={{position: 'absolute', top: '1031px', left: '453px', whiteSpace: 'nowrap'}} className="ft30"><b>085</b></p>
          <p style={{position: 'absolute', top: '995px', left: '450px', whiteSpace: 'nowrap'}} className="ft39"><b>If the corporation is exempt from tax under section 149, tick one of the&nbsp;<br />following boxes:</b></p>
          <p style={{position: 'absolute', top: '1029px', left: '522px', whiteSpace: 'nowrap'}} className="ft33">1 &nbsp;Exempt under paragraph 149(1)(e) or (l)</p>
          <p style={{position: 'absolute', top: '1047px', left: '522px', whiteSpace: 'nowrap'}} className="ft33">2 &nbsp;Exempt under paragraph 149(1)(j)</p>
          <p style={{position: 'absolute', top: '1065px', left: '522px', whiteSpace: 'nowrap'}} className="ft33">4 &nbsp;Exempt under other paragraphs of section 149</p>
          <p style={{position: 'absolute', top: '1090px', left: '402px', whiteSpace: 'nowrap'}} className="ft35"><b>Do not use this area</b></p>
          <p style={{position: 'absolute', top: '1112px', left: '35px', whiteSpace: 'nowrap'}} className="ft30"><b>095</b></p>
          <p style={{position: 'absolute', top: '1112px', left: '318px', whiteSpace: 'nowrap'}} className="ft30"><b>096</b></p>
          <p style={{position: 'absolute', top: '1112px', left: '593px', whiteSpace: 'nowrap'}} className="ft30"><b>898</b></p>
          <p style={{position: 'absolute', top: '1145px', left: '31px', whiteSpace: 'nowrap'}} className="ft37">T2 E (23)</p>
          <p style={{position: 'absolute', top: '1145px', left: '346px', whiteSpace: 'nowrap'}} className="ft37">(Ce formulaire est disponible en français.)</p>
          <p style={{position: 'absolute', top: '1145px', left: '693px', whiteSpace: 'nowrap'}} className="ft37">Page 1 of 9</p>
          <p style={{position: 'absolute', top: '256px', left: '564px', whiteSpace: 'nowrap'}} className="ft35"><b>R&nbsp;C</b></p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\tp {margin: 0; padding: 0;}\t.ft40{font-size:11px;font-family:Helvetica;color:#000000;}\n\t.ft41{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft42{font-size:13px;font-family:Helvetica;color:#000000;}\n\t.ft43{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft44{font-size:10px;font-family:Helvetica;color:#ffffff;}\n\t.ft45{font-size:8px;font-family:Helvetica;color:#000000;}\n\t.ft46{font-size:10px;line-height:13px;font-family:Helvetica;color:#000000;}\n" }} />
        <div id="page4-div" style={{position: 'relative', width: '918px', height: '1188px'}}>
          <img width={918} height={1188} src="target004.png" alt="background image" />
          <p style={{position: 'absolute', top: '34px', left: '720px', whiteSpace: 'nowrap'}} className="ft40"><b>Protected B&nbsp;</b></p>
          <p style={{position: 'absolute', top: '36px', left: '799px', whiteSpace: 'nowrap'}} className="ft41">when completed</p>
          <p style={{position: 'absolute', top: '42px', left: '65px', whiteSpace: 'nowrap'}} className="ft42"><b>Attachments</b></p>
          <p style={{position: 'absolute', top: '68px', left: '41px', whiteSpace: 'nowrap'}} className="ft43"><b>Financial statement information:&nbsp;</b>Use GIFI schedules 100, 125, and 141.</p>
          <p style={{position: 'absolute', top: '91px', left: '41px', whiteSpace: 'nowrap'}} className="ft43"><b>Schedules</b>&nbsp;– Answer the following questions. For each&nbsp;<b>yes</b>&nbsp;response,&nbsp;<b>attach</b>&nbsp;the schedule to the T2 return, unless otherwise instructed.</p>
          <p style={{position: 'absolute', top: '109px', left: '789px', whiteSpace: 'nowrap'}} className="ft43"><b>Yes</b></p>
          <p style={{position: 'absolute', top: '109px', left: '822px', whiteSpace: 'nowrap'}} className="ft43"><b>Schedule</b></p>
          {state.exemption == "YES"?
          <p style={{position: 'absolute', top: '120px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>150</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='x'/>
          </p>:
          <p style={{position: 'absolute', top: '122px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>150</b>
          </p>}
          <p style={{position: 'absolute', top: '131px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation related to any other corporations?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '127px', left: '845px', whiteSpace: 'nowrap'}} className="ft41">9</p>
          {state.associated == "YES"?
          <p style={{position: 'absolute', top: '138px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>160</b>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='x'/>
          </p>:
           <p style={{position: 'absolute', top: '144px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>160</b>
           </p>}
          <p style={{position: 'absolute', top: '149px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation an associated CCPC?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '145px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">23</p>
          <p style={{position: 'absolute', top: '162px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>161</b></p>
          <p style={{position: 'absolute', top: '167px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation an associated CCPC that is claiming the expenditure limit?&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '163px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">49</p>
          <p style={{position: 'absolute', top: '180px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>151</b></p>
          <p style={{position: 'absolute', top: '185px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Does the corporation have any non-resident shareholders who own voting shares?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '181px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">19</p>
          <p style={{position: 'absolute', top: '216px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>162</b></p>
          <p style={{position: 'absolute', top: '208px', left: '41px', whiteSpace: 'nowrap'}} className="ft46">Has the corporation had any transactions, including section 85 transfers, with its shareholders, officers, or employees,&nbsp;<br />other than transactions in the ordinary course of business? Exclude non-arm's length transactions with non-residents&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '217px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">11</p>
          <p style={{position: 'absolute', top: '252px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>163</b></p>
          <p style={{position: 'absolute', top: '244px', left: '41px', whiteSpace: 'nowrap'}} className="ft46">If you answered&nbsp;<b>yes</b>&nbsp;to the above question, and the transaction was between corporations not dealing at arm's length,&nbsp;<br />were all or substantially all of the assets of the transferor disposed of to the transferee?&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '253px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">44</p>
          <p style={{position: 'absolute', top: '270px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>164</b></p>
          <p style={{position: 'absolute', top: '275px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Has the corporation paid any royalties, management fees, or other similar payments to residents of Canada?&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '271px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">14</p>
          <p style={{position: 'absolute', top: '288px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>165</b></p>
          <p style={{position: 'absolute', top: '293px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation claiming a deduction for payments to a type of employee benefit plan?&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '289px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">15</p>
          <p style={{position: 'absolute', top: '306px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>166</b></p>
          <p style={{position: 'absolute', top: '311px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation claiming a loss or deduction from a tax shelter?&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '307px', left: '831px', whiteSpace: 'nowrap'}} className="ft41">T5004</p>
          <p style={{position: 'absolute', top: '324px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>167</b></p>
          <p style={{position: 'absolute', top: '329px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation a member of a partnership for which a partnership account number has been assigned?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '325px', left: '831px', whiteSpace: 'nowrap'}} className="ft41">T5013</p>
          <p style={{position: 'absolute', top: '360px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>168</b></p>
          <p style={{position: 'absolute', top: '352px', left: '41px', whiteSpace: 'nowrap'}} className="ft46">Did the corporation, a foreign affiliate controlled by the corporation, or any other corporation or trust that did not deal at&nbsp;arm's length&nbsp;<br />with the corporation have a beneficial interest in a non-resident discretionary trust (without reference to section 94)?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '361px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">22</p>
          <p style={{position: 'absolute', top: '378px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>169</b></p>
          <p style={{position: 'absolute', top: '383px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Did the corporation own any shares in one or more foreign affiliates in the tax year?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '379px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">25</p>
          <p style={{position: 'absolute', top: '410px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>170</b></p>
          <p style={{position: 'absolute', top: '401px', left: '41px', whiteSpace: 'nowrap'}} className="ft46">Has the corporation made any payments to non-residents of Canada under subsections 202(1) and/or 105(1) of&nbsp;<br />the Income Tax Regulations?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '410px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">29</p>
          <p style={{position: 'absolute', top: '428px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>171</b></p>
          <p style={{position: 'absolute', top: '433px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Did the corporation have a total amount over CAN$1 million of reportable transactions with non-arm's length non-residents?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '428px', left: '835px', whiteSpace: 'nowrap'}} className="ft41">T106</p>
          {state.shareholders == "YES"?
          <p style={{position: 'absolute', top: '459px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>173</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='x'/>
          </p>:
            <p style={{position: 'absolute', top: '464px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>173</b>
            </p>}
          <p style={{position: 'absolute', top: '455px', left: '41px', whiteSpace: 'nowrap'}} className="ft46">For private corporations: Does the corporation have any shareholders who own 10% or more of the corporation's common&nbsp;<br />and/or preferred shares?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '464px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">50</p>
          <p style={{position: 'absolute', top: '482px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>172</b></p>
          <p style={{position: 'absolute', top: '487px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Has the corporation made payments to, or received amounts from, a retirement compensation plan arrangement during the year?</p>
          <p style={{position: 'absolute', top: '500px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>180</b></p>
          <p style={{position: 'absolute', top: '505px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Does the corporation earn income from one or more Internet web pages or websites?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '500px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">88</p>
          <p style={{position: 'absolute', top: '518px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>201</b></p>
          <p style={{position: 'absolute', top: '523px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the net income/loss shown on the financial statements different from the net income/loss for income tax purposes?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '518px', left: '845px', whiteSpace: 'nowrap'}} className="ft41">1</p>
          <p style={{position: 'absolute', top: '536px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>202</b></p>
          <p style={{position: 'absolute', top: '541px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Has the corporation made any charitable donations; gifts of cultural or ecological property; or gifts of medicine?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '536px', left: '845px', whiteSpace: 'nowrap'}} className="ft41">2</p>
          <p style={{position: 'absolute', top: '554px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>203</b></p>
          <p style={{position: 'absolute', top: '559px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Has the corporation received any dividends or paid any taxable dividends for purposes of the dividend refund?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '554px', left: '845px', whiteSpace: 'nowrap'}} className="ft41">3</p>
          <p style={{position: 'absolute', top: '572px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>204</b></p>
          <p style={{position: 'absolute', top: '576px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation claiming any type of losses?&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '572px', left: '845px', whiteSpace: 'nowrap'}} className="ft41">4</p>
          <p style={{position: 'absolute', top: '608px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>205</b></p>
          <p style={{position: 'absolute', top: '599px', left: '41px', whiteSpace: 'nowrap'}} className="ft46">Is the corporation claiming a provincial or territorial tax credit or does it have a&nbsp;<br />permanent establishment in more than one jurisdiction?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '608px', left: '845px', whiteSpace: 'nowrap'}} className="ft41">5</p>
          <p style={{position: 'absolute', top: '626px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>206</b></p>
          <p style={{position: 'absolute', top: '630px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Has the corporation realized any capital gains or incurred any capital losses during the tax year?&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '626px', left: '845px', whiteSpace: 'nowrap'}} className="ft41">6</p>
          <p style={{position: 'absolute', top: '711px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>207</b></p>
          <p style={{position: 'absolute', top: '649px', left: '41px', whiteSpace: 'nowrap'}} className="ft46">i) Is the corporation a CCPC and reporting a) income or loss from property (other than dividends deductible on line 320 of the&nbsp;T2&nbsp;<br />return), b) income from a partnership, c) income from a foreign business, d) income from a personal services business, e) income&nbsp;<br />referred to in clause 125(1)(a)(i)(C) or 125(1)(a)(i)(B), f) aggregate investment income as defined in subsection 129(4), or g)&nbsp;an&nbsp;<br />amount assigned to it under subsection 125(3.2) or 125(8); or&nbsp;<br />ii) Is the corporation a member of a partnership and assigning its specified partnership business limit to a designated member&nbsp;under&nbsp;<br />subsection 125(8)?&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '712px', left: '845px', whiteSpace: 'nowrap'}} className="ft41">7</p>
          <p style={{position: 'absolute', top: '729px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>208</b></p>
          <p style={{position: 'absolute', top: '734px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Does the corporation have any property that is eligible for capital cost allowance?&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '730px', left: '845px', whiteSpace: 'nowrap'}} className="ft41">8</p>
          <p style={{position: 'absolute', top: '747px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>212</b></p>
          <p style={{position: 'absolute', top: '752px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Does the corporation have any resource-related deductions?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '748px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">12</p>
          <p style={{position: 'absolute', top: '765px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>213</b></p>
          <p style={{position: 'absolute', top: '770px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation claiming deductible reserves?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '766px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">13</p>
          <p style={{position: 'absolute', top: '783px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>216</b></p>
          <p style={{position: 'absolute', top: '788px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation claiming a patronage dividend deduction?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '784px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">16</p>
          <p style={{position: 'absolute', top: '801px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>217</b></p>
          <p style={{position: 'absolute', top: '806px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is&nbsp;the&nbsp;corporation&nbsp;a&nbsp;credit&nbsp;union&nbsp;claiming&nbsp;a&nbsp;deduction&nbsp;for&nbsp;allocations&nbsp;in&nbsp;proportion&nbsp;to&nbsp;borrowing&nbsp;or&nbsp;a&nbsp;provincial&nbsp;credit&nbsp;union&nbsp;tax&nbsp;reduction?</p>
          <p style={{position: 'absolute', top: '802px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">17</p>
          <p style={{position: 'absolute', top: '819px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>218</b></p>
          <p style={{position: 'absolute', top: '824px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation an investment corporation or a mutual fund corporation?&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '820px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">18</p>
          <p style={{position: 'absolute', top: '837px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>220</b></p>
          <p style={{position: 'absolute', top: '842px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation carrying on business in Canada as a non-resident corporation?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '838px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">20</p>
          <p style={{position: 'absolute', top: '855px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>221</b></p>
          <p style={{position: 'absolute', top: '860px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation claiming any federal, provincial, or territorial foreign tax credits, or any federal logging tax credits?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '856px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">21</p>
          <p style={{position: 'absolute', top: '873px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>227</b></p>
          <p style={{position: 'absolute', top: '878px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Does the corporation have any Canadian manufacturing and processing profits or zero-emission technology manufacturing profits?&nbsp;</p>
          <p style={{position: 'absolute', top: '874px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">27</p>
          <p style={{position: 'absolute', top: '891px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>231</b></p>
          <p style={{position: 'absolute', top: '896px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation claiming an investment tax credit?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '892px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">31</p>
          <p style={{position: 'absolute', top: '909px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>232</b></p>
          <p style={{position: 'absolute', top: '914px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation claiming any scientific research and experimental development (SR&amp;ED) expenditures?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '910px', left: '835px', whiteSpace: 'nowrap'}} className="ft41">T661</p>
          <p style={{position: 'absolute', top: '927px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>233</b></p>
          <p style={{position: 'absolute', top: '932px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the total taxable capital employed in Canada of the corporation and its related corporations over $10,000,000?&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '928px', left: '825px', whiteSpace: 'nowrap'}} className="ft41">33/34/35</p>
          <p style={{position: 'absolute', top: '945px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>234</b></p>
          <p style={{position: 'absolute', top: '950px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the total taxable capital employed in Canada of the corporation and its associated corporations over $10,000,000? .&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '963px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>238</b></p>
          <p style={{position: 'absolute', top: '968px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation subject to gross Part VI tax on capital of financial institutions?&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '964px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">38</p>
          <p style={{position: 'absolute', top: '981px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>242</b></p>
          <p style={{position: 'absolute', top: '986px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation claiming a Part I tax credit?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '982px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">42</p>
          <p style={{position: 'absolute', top: '999px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>243</b></p>
          <p style={{position: 'absolute', top: '1004px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation subject to Part IV.1 tax on dividends received on taxable preferred shares or Part VI.1 tax on dividends paid? .&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1000px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">43</p>
          <p style={{position: 'absolute', top: '1017px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>244</b></p>
          <p style={{position: 'absolute', top: '1022px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation agreeing to a transfer of the liability for Part VI.1 tax?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1018px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">45</p>
          <p style={{position: 'absolute', top: '1049px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>250</b></p>
          <p style={{position: 'absolute', top: '1040px', left: '41px', whiteSpace: 'nowrap'}} className="ft46">For financial institutions: Is the corporation a member of a related group of financial institutions&nbsp;<br />with one or more members subject to gross Part VI tax?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1049px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">39</p>
          <p style={{position: 'absolute', top: '1067px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>253</b></p>
          <p style={{position: 'absolute', top: '1072px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation claiming a Canadian film or video production tax credit?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1067px', left: '831px', whiteSpace: 'nowrap'}} className="ft41">T1131</p>
          <p style={{position: 'absolute', top: '1085px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>254</b></p>
          <p style={{position: 'absolute', top: '1090px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation claiming a film or video production services tax credit?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1085px', left: '831px', whiteSpace: 'nowrap'}} className="ft41">T1177</p>
          <p style={{position: 'absolute', top: '1103px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>272</b></p>
          <p style={{position: 'absolute', top: '1107px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation claiming a Canadian journalism labour tax credit?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1103px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">58</p>
          <p style={{position: 'absolute', top: '1121px', left: '759px', whiteSpace: 'nowrap'}} className="ft44"><b>255</b></p>
          <p style={{position: 'absolute', top: '1126px', left: '41px', whiteSpace: 'nowrap'}} className="ft41">Is the corporation subject to Part XIII.1 tax? (Show your calculations on a sheet that you identify as Schedule 92.)&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1121px', left: '842px', whiteSpace: 'nowrap'}} className="ft41">92</p>
          <p style={{position: 'absolute', top: '1145px', left: '31px', whiteSpace: 'nowrap'}} className="ft45">T2 E (23)</p>
          <p style={{position: 'absolute', top: '1145px', left: '833px', whiteSpace: 'nowrap'}} className="ft45">Page 2 of 9</p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\tp {margin: 0; padding: 0;}\t.ft50{font-size:11px;font-family:Helvetica;color:#000000;}\n\t.ft51{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft52{font-size:13px;font-family:Helvetica;color:#000000;}\n\t.ft53{font-size:10px;font-family:Helvetica;color:#ffffff;}\n\t.ft54{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft55{font-size:13px;font-family:Times;color:#000000;}\n\t.ft56{font-size:8px;font-family:Helvetica;color:#000000;}\n\t.ft57{font-size:10px;line-height:13px;font-family:Helvetica;color:#000000;}\n" }} />
        <div id="page5-div" style={{position: 'relative', width: '918px', height: '1188px'}}>
          <img width={918} height={1188} src="target005.png" alt="background image" />
          <p style={{position: 'absolute', top: '34px', left: '720px', whiteSpace: 'nowrap'}} className="ft50"><b>Protected B&nbsp;</b></p>
          <p style={{position: 'absolute', top: '36px', left: '799px', whiteSpace: 'nowrap'}} className="ft51">when completed</p>
          <p style={{position: 'absolute', top: '42px', left: '67px', whiteSpace: 'nowrap'}} className="ft52"><b>Attachments (continued)</b></p>
          <p style={{position: 'absolute', top: '63px', left: '759px', whiteSpace: 'nowrap'}} className="ft53"><b>271</b></p>
          <p style={{position: 'absolute', top: '68px', left: '41px', whiteSpace: 'nowrap'}} className="ft51">Did the corporation have any foreign affiliates in the tax year?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '64px', left: '831px', whiteSpace: 'nowrap'}} className="ft51">T1134</p>
          <p style={{position: 'absolute', top: '95px', left: '759px', whiteSpace: 'nowrap'}} className="ft53"><b>259</b></p>
          <p style={{position: 'absolute', top: '86px', left: '41px', whiteSpace: 'nowrap'}} className="ft57">Did the corporation own or hold specified foreign property where the total cost amount of all such property, at any time in the&nbsp;year,&nbsp;<br />was more than CAN$100,000?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '95px', left: '831px', whiteSpace: 'nowrap'}} className="ft51">T1135</p>
          <p style={{position: 'absolute', top: '113px', left: '759px', whiteSpace: 'nowrap'}} className="ft53"><b>260</b></p>
          <p style={{position: 'absolute', top: '118px', left: '41px', whiteSpace: 'nowrap'}} className="ft51">Did the corporation transfer or loan property to a non-resident trust?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '113px', left: '831px', whiteSpace: 'nowrap'}} className="ft51">T1141</p>
          <p style={{position: 'absolute', top: '131px', left: '759px', whiteSpace: 'nowrap'}} className="ft53"><b>261</b></p>
          <p style={{position: 'absolute', top: '136px', left: '41px', whiteSpace: 'nowrap'}} className="ft51">Did the corporation receive a distribution from or was it indebted to a non-resident trust in the year?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '131px', left: '831px', whiteSpace: 'nowrap'}} className="ft51">T1142</p>
          <p style={{position: 'absolute', top: '149px', left: '759px', whiteSpace: 'nowrap'}} className="ft53"><b>262</b></p>
          <p style={{position: 'absolute', top: '154px', left: '41px', whiteSpace: 'nowrap'}} className="ft51">Has the corporation entered into an agreement to allocate assistance for SR&amp;ED carried out in Canada?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '149px', left: '831px', whiteSpace: 'nowrap'}} className="ft51">T1145</p>
          <p style={{position: 'absolute', top: '167px', left: '759px', whiteSpace: 'nowrap'}} className="ft53"><b>263</b></p>
          <p style={{position: 'absolute', top: '172px', left: '41px', whiteSpace: 'nowrap'}} className="ft51">Has the corporation entered into an agreement to transfer qualified expenditures incurred in respect of SR&amp;ED contracts?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '167px', left: '831px', whiteSpace: 'nowrap'}} className="ft51">T1146</p>
          <p style={{position: 'absolute', top: '203px', left: '759px', whiteSpace: 'nowrap'}} className="ft53"><b>264</b></p>
          <p style={{position: 'absolute', top: '194px', left: '41px', whiteSpace: 'nowrap'}} className="ft57">Has the corporation entered into an agreement with other associated corporations for salary or wages of&nbsp;<br />specified employees for SR&amp;ED?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '203px', left: '831px', whiteSpace: 'nowrap'}} className="ft51">T1174</p>
          <p style={{position: 'absolute', top: '221px', left: '759px', whiteSpace: 'nowrap'}} className="ft53"><b>265</b></p>
          <p style={{position: 'absolute', top: '226px', left: '41px', whiteSpace: 'nowrap'}} className="ft51">Did the corporation pay taxable dividends (other than capital gains dividends) in the tax year?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '221px', left: '842px', whiteSpace: 'nowrap'}} className="ft51">55</p>
          <p style={{position: 'absolute', top: '239px', left: '759px', whiteSpace: 'nowrap'}} className="ft53"><b>266</b></p>
          <p style={{position: 'absolute', top: '244px', left: '41px', whiteSpace: 'nowrap'}} className="ft51">Has the corporation made an election under subsection 89(11) not to be a CCPC?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '239px', left: '831px', whiteSpace: 'nowrap'}} className="ft51">T2002</p>
          <p style={{position: 'absolute', top: '257px', left: '759px', whiteSpace: 'nowrap'}} className="ft53"><b>267</b></p>
          <p style={{position: 'absolute', top: '262px', left: '41px', whiteSpace: 'nowrap'}} className="ft51">Has the corporation revoked any previous election made under subsection 89(11)?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '257px', left: '831px', whiteSpace: 'nowrap'}} className="ft51">T2002</p>
          <p style={{position: 'absolute', top: '293px', left: '759px', whiteSpace: 'nowrap'}} className="ft53"><b>268</b></p>
          <p style={{position: 'absolute', top: '284px', left: '41px', whiteSpace: 'nowrap'}} className="ft57">Did the corporation (CCPC or deposit insurance corporation (DIC)) pay eligible dividends, or did its&nbsp;<br />general rate income pool (GRIP) change in the tax year?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '293px', left: '842px', whiteSpace: 'nowrap'}} className="ft51">53</p>
          <p style={{position: 'absolute', top: '329px', left: '759px', whiteSpace: 'nowrap'}} className="ft53"><b>269</b></p>
          <p style={{position: 'absolute', top: '320px', left: '41px', whiteSpace: 'nowrap'}} className="ft57">Did the corporation (other than a CCPC or DIC) pay eligible dividends, or did its low rate income pool (LRIP)&nbsp;<br />change in the tax year?&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '329px', left: '842px', whiteSpace: 'nowrap'}} className="ft51">54</p>
          <p style={{position: 'absolute', top: '347px', left: '759px', whiteSpace: 'nowrap'}} className="ft53"><b>273</b></p>
          <p style={{position: 'absolute', top: '351px', left: '41px', whiteSpace: 'nowrap'}} className="ft51">Is the corporation claiming a return of fuel charge proceeds to farmers tax credit?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '347px', left: '842px', whiteSpace: 'nowrap'}} className="ft51">63</p>
          <p style={{position: 'absolute', top: '365px', left: '759px', whiteSpace: 'nowrap'}} className="ft53"><b>274</b></p>
          <p style={{position: 'absolute', top: '369px', left: '41px', whiteSpace: 'nowrap'}} className="ft51">Are you an employer reporting a non-qualified security agreement under subsection 110(1.9)?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '365px', left: '842px', whiteSpace: 'nowrap'}} className="ft51">59</p>
          <p style={{position: 'absolute', top: '383px', left: '759px', whiteSpace: 'nowrap'}} className="ft53"><b>275</b></p>
          <p style={{position: 'absolute', top: '387px', left: '41px', whiteSpace: 'nowrap'}} className="ft51">Is the corporation claiming an air quality improvement tax credit?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '383px', left: '842px', whiteSpace: 'nowrap'}} className="ft51">65</p>
          <p style={{position: 'absolute', top: '401px', left: '759px', whiteSpace: 'nowrap'}} className="ft53"><b>276</b></p>
          <p style={{position: 'absolute', top: '405px', left: '41px', whiteSpace: 'nowrap'}} className="ft51">Is the corporation subject to the additional 1.5% tax on banks and life insurers?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '401px', left: '842px', whiteSpace: 'nowrap'}} className="ft51">68</p>
          <p style={{position: 'absolute', top: '438px', left: '64px', whiteSpace: 'nowrap'}} className="ft52"><b>Additional information</b></p>
          <p style={{position: 'absolute', top: '459px', left: '692px', whiteSpace: 'nowrap'}} className="ft53"><b>270</b></p>
          <p style={{position: 'absolute', top: '464px', left: '36px', whiteSpace: 'nowrap'}} className="ft51">Did the corporation use the International Financial Reporting Standards (IFRS) when it prepared its financial statements?</p>
          <p style={{position: 'absolute', top: '459px', left: '733px', whiteSpace: 'nowrap'}} className="ft51">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '454px', left: '819px', whiteSpace: 'nowrap'}} className="ft51">&nbsp; &nbsp;No
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='x'/>
          </p>
          {state.inactive == "YES"?
          <p style={{position: 'absolute', top: '473px', left: '694px', whiteSpace: 'nowrap'}} className="ft53"><b>280</b>
          &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='x'/>
          </p>: 
           <p style={{position: 'absolute', top: '474px', left: '692px', whiteSpace: 'nowrap'}} className="ft53"><b>280</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='x'/>
           </p>
           } 
          <p style={{position: 'absolute', top: '482px', left: '36px', whiteSpace: 'nowrap'}} className="ft51">Is the corporation inactive?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '477px', left: '733px', whiteSpace: 'nowrap'}} className="ft51">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '477px', left: '819px', whiteSpace: 'nowrap'}} className="ft51">&nbsp; &nbsp;No</p>
          <p style={{position: 'absolute', top: '505px', left: '36px', whiteSpace: 'nowrap'}} className="ft57">Specify the principal products mined, manufactured,&nbsp;<br />sold, constructed, or services provided, giving the&nbsp;<br />approximate percentage of the total revenue that&nbsp;<br />each product or service represents.</p>
          <p style={{position: 'absolute', top: '500px', left: '336px', whiteSpace: 'nowrap'}} className="ft53"><b>284</b>
          &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input  style={{width:'190px'}} type="text" className="borderless-input" defaultValue={state.principal_prod}/>
          </p>
          <p style={{position: 'absolute', top: '500px', left: '777px', whiteSpace: 'nowrap'}} className="ft53"><b>285</b>
          &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input  style={{width:'100px'}} type="text" className="borderless-input" defaultValue={state.principal_percentage}/>
          </p>
          <p style={{position: 'absolute', top: '505px', left: '864px', whiteSpace: 'nowrap'}} className="ft51">%</p>
          <p style={{position: 'absolute', top: '518px', left: '336px', whiteSpace: 'nowrap'}} className="ft53"><b>286</b>
          &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input  style={{width:'190px'}} type="text" className="borderless-input" defaultValue={state.principal_prod1}/>
            </p>
          <p style={{position: 'absolute', top: '518px', left: '777px', whiteSpace: 'nowrap'}} className="ft53"><b>287</b>
          &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input  style={{width:'100px'}} type="text" className="borderless-input" defaultValue={state.principal_percentage1}/>
          </p>
          <p style={{position: 'absolute', top: '523px', left: '864px', whiteSpace: 'nowrap'}} className="ft51">%</p>
          <p style={{position: 'absolute', top: '537px', left: '336px', whiteSpace: 'nowrap'}} className="ft53"><b>288</b>
          &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input  style={{width:'190px'}} type="text" className="borderless-input" defaultValue={state.principal_prod2}/>
            </p>
          <p style={{position: 'absolute', top: '538px', left: '777px', whiteSpace: 'nowrap'}} className="ft53"><b>289</b>
          &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input  style={{width:'100px'}} type="text" className="borderless-input" defaultValue={state.principal_percentage1}/>
          </p>
          <p style={{position: 'absolute', top: '541px', left: '864px', whiteSpace: 'nowrap'}} className="ft51">%</p>
          {state.imigrate == "YES"?
          <p style={{position: 'absolute', top: '562px', left: '692px', whiteSpace: 'nowrap'}} className="ft53"><b>291</b>
          &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='x'/>
          </p>:
           <p style={{position: 'absolute', top: '564px', left: '692px', whiteSpace: 'nowrap'}} className="ft53"><b>291</b>
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='x'/>
           </p>}
          <p style={{position: 'absolute', top: '572px', left: '36px', whiteSpace: 'nowrap'}} className="ft51">Did the corporation immigrate to Canada during the tax year?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '567px', left: '733px', whiteSpace: 'nowrap'}} className="ft51">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '567px', left: '819px', whiteSpace: 'nowrap'}} className="ft51">&nbsp; &nbsp;No</p>
          {state.emigrate == "YES"?
          <p style={{position: 'absolute', top: '580px', left: '692px', whiteSpace: 'nowrap'}} className="ft53"><b>292</b>
          &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='x'/>
          </p>:
          <p style={{position: 'absolute', top: '580px', left: '692px', whiteSpace: 'nowrap'}} className="ft53"><b>292</b>
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='x'/>
          </p>
            }
          <p style={{position: 'absolute', top: '590px', left: '36px', whiteSpace: 'nowrap'}} className="ft51">Did the corporation emigrate from Canada during the tax year?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '585px', left: '733px', whiteSpace: 'nowrap'}} className="ft51">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '585px', left: '819px', whiteSpace: 'nowrap'}} className="ft51">&nbsp; &nbsp;No</p>
          <p style={{position: 'absolute', top: '603px', left: '692px', whiteSpace: 'nowrap'}} className="ft53"><b>293</b></p>
          <p style={{position: 'absolute', top: '608px', left: '36px', whiteSpace: 'nowrap'}} className="ft51">Do you want to be considered as a quarterly instalment remitter if you are eligible?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '603px', left: '733px', whiteSpace: 'nowrap'}} className="ft51">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '603px', left: '819px', whiteSpace: 'nowrap'}} className="ft51">&nbsp; &nbsp;No</p>
          <p style={{position: 'absolute', top: '648px', left: '692px', whiteSpace: 'nowrap'}} className="ft53"><b>294</b></p>
          <p style={{position: 'absolute', top: '640px', left: '36px', whiteSpace: 'nowrap'}} className="ft57">If the corporation was eligible to remit instalments on a quarterly basis for part of the tax year, provide the date the&nbsp;<br />corporation ceased to be eligible&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '630px', left: '745px', whiteSpace: 'nowrap'}} className="ft51">Year &nbsp;Month &nbsp;Day</p>
          <p style={{position: 'absolute', top: '671px', left: '692px', whiteSpace: 'nowrap'}} className="ft53"><b>295</b></p>
          <p style={{position: 'absolute', top: '676px', left: '36px', whiteSpace: 'nowrap'}} className="ft51">If the corporation's major business activity is construction, did you have any subcontractors during the tax year?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '670px', left: '733px', whiteSpace: 'nowrap'}} className="ft51">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '670px', left: '819px', whiteSpace: 'nowrap'}} className="ft51">&nbsp; &nbsp;No</p>
          <p style={{position: 'absolute', top: '699px', left: '63px', whiteSpace: 'nowrap'}} className="ft52"><b>Taxable income</b></p>
          <p style={{position: 'absolute', top: '720px', left: '719px', whiteSpace: 'nowrap'}} className="ft53"><b>300</b></p>
          <p style={{position: 'absolute', top: '721px', left: '864px', whiteSpace: 'nowrap'}} className="ft51">A</p>
          <p style={{position: 'absolute', top: '725px', left: '41px', whiteSpace: 'nowrap'}} className="ft51">Net income or (loss) for income tax purposes from Schedule 1, financial statements, or GIFI&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '743px', left: '54px', whiteSpace: 'nowrap'}} className="ft54"><b>Deduct:</b></p>
          <p style={{position: 'absolute', top: '761px', left: '566px', whiteSpace: 'nowrap'}} className="ft53"><b>311</b></p>
          <p style={{position: 'absolute', top: '766px', left: '54px', whiteSpace: 'nowrap'}} className="ft51">Charitable donations from Schedule 2&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '779px', left: '566px', whiteSpace: 'nowrap'}} className="ft53"><b>313</b></p>
          <p style={{position: 'absolute', top: '784px', left: '54px', whiteSpace: 'nowrap'}} className="ft51">Cultural gifts from Schedule 2&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '797px', left: '566px', whiteSpace: 'nowrap'}} className="ft53"><b>314</b></p>
          <p style={{position: 'absolute', top: '802px', left: '54px', whiteSpace: 'nowrap'}} className="ft51">Ecological gifts from Schedule 2&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '815px', left: '566px', whiteSpace: 'nowrap'}} className="ft53"><b>315</b></p>
          <p style={{position: 'absolute', top: '820px', left: '54px', whiteSpace: 'nowrap'}} className="ft51">Gifts of medicine made before March 22, 2017, from Schedule 2&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '846px', left: '566px', whiteSpace: 'nowrap'}} className="ft53"><b>320</b></p>
          <p style={{position: 'absolute', top: '838px', left: '54px', whiteSpace: 'nowrap'}} className="ft57">Taxable dividends deductible under section 112 or 113, or subsection 138(6)&nbsp;<br />from Schedule 3&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '864px', left: '566px', whiteSpace: 'nowrap'}} className="ft53"><b>325</b></p>
          <p style={{position: 'absolute', top: '869px', left: '54px', whiteSpace: 'nowrap'}} className="ft51">Part VI.1 tax deduction<b>*&nbsp;</b>.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '882px', left: '566px', whiteSpace: 'nowrap'}} className="ft53"><b>331</b></p>
          <p style={{position: 'absolute', top: '887px', left: '54px', whiteSpace: 'nowrap'}} className="ft51">Non-capital losses of previous tax years from Schedule 4&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '900px', left: '566px', whiteSpace: 'nowrap'}} className="ft53"><b>332</b></p>
          <p style={{position: 'absolute', top: '905px', left: '54px', whiteSpace: 'nowrap'}} className="ft51">Net capital losses of previous tax years from Schedule 4&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '918px', left: '566px', whiteSpace: 'nowrap'}} className="ft53"><b>333</b></p>
          <p style={{position: 'absolute', top: '923px', left: '54px', whiteSpace: 'nowrap'}} className="ft51">Restricted farm losses of previous tax years from Schedule 4&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '936px', left: '566px', whiteSpace: 'nowrap'}} className="ft53"><b>334</b></p>
          <p style={{position: 'absolute', top: '941px', left: '54px', whiteSpace: 'nowrap'}} className="ft51">Farm losses of previous tax years from Schedule 4&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '954px', left: '566px', whiteSpace: 'nowrap'}} className="ft53"><b>335</b></p>
          <p style={{position: 'absolute', top: '959px', left: '54px', whiteSpace: 'nowrap'}} className="ft51">Limited partnership losses of previous tax years from Schedule 4&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '972px', left: '566px', whiteSpace: 'nowrap'}} className="ft53"><b>340</b></p>
          <p style={{position: 'absolute', top: '977px', left: '54px', whiteSpace: 'nowrap'}} className="ft51">Taxable capital gains or taxable dividends allocated from a central credit union&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '990px', left: '566px', whiteSpace: 'nowrap'}} className="ft53"><b>350</b></p>
          <p style={{position: 'absolute', top: '995px', left: '54px', whiteSpace: 'nowrap'}} className="ft51">Prospector's and grubstaker's shares&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1008px', left: '566px', whiteSpace: 'nowrap'}} className="ft53"><b>352</b></p>
          <p style={{position: 'absolute', top: '1013px', left: '54px', whiteSpace: 'nowrap'}} className="ft51">Employer deduction for non-qualified securities.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1027px', left: '864px', whiteSpace: 'nowrap'}} className="ft51">B</p>
          <p style={{position: 'absolute', top: '1031px', left: '545px', whiteSpace: 'nowrap'}} className="ft51">Subtotal</p>
          <p style={{position: 'absolute', top: '1022px', left: '726px', whiteSpace: 'nowrap'}} className="ft55">Ź</p>
          <p style={{position: 'absolute', top: '1049px', left: '864px', whiteSpace: 'nowrap'}} className="ft51">C</p>
          <p style={{position: 'absolute', top: '1049px', left: '424px', whiteSpace: 'nowrap'}} className="ft51">Subtotal (amount A&nbsp;<b>minus</b>&nbsp;amount B) (if negative, enter "0")</p>
          <p style={{position: 'absolute', top: '1071px', left: '719px', whiteSpace: 'nowrap'}} className="ft53"><b>355</b></p>
          <p style={{position: 'absolute', top: '1072px', left: '864px', whiteSpace: 'nowrap'}} className="ft51">D</p>
          <p style={{position: 'absolute', top: '1076px', left: '41px', whiteSpace: 'nowrap'}} className="ft51">Section 110.5 additions or subparagraph 115(1)(a)(vii) additions&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1089px', left: '719px', whiteSpace: 'nowrap'}} className="ft53"><b>360</b></p>
          <p style={{position: 'absolute', top: '1094px', left: '41px', whiteSpace: 'nowrap'}} className="ft54"><b>Taxable income</b>&nbsp;(amount C&nbsp;<b>plus</b>&nbsp;amount D)&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1121px', left: '41px', whiteSpace: 'nowrap'}} className="ft51">* This amount is equal to 3.5 times the Part VI.1 tax payable at line 724 on page 9.</p>
          <p style={{position: 'absolute', top: '1145px', left: '31px', whiteSpace: 'nowrap'}} className="ft56">T2 E (23)</p>
          <p style={{position: 'absolute', top: '1145px', left: '833px', whiteSpace: 'nowrap'}} className="ft56">Page 3 of 9</p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\tp {margin: 0; padding: 0;}\t.ft60{font-size:11px;font-family:Helvetica;color:#000000;}\n\t.ft61{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft62{font-size:13px;font-family:Helvetica;color:#000000;}\n\t.ft63{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft64{font-size:10px;font-family:Helvetica;color:#ffffff;}\n\t.ft65{font-size:7px;font-family:Helvetica;color:#000000;}\n\t.ft66{font-size:14px;font-family:Helvetica;color:#000000;}\n\t.ft67{font-size:13px;font-family:Times;color:#000000;}\n\t.ft68{font-size:13px;font-family:Helvetica;color:#000000;}\n\t.ft69{font-size:8px;font-family:Helvetica;color:#000000;}\n\t.ft610{font-size:10px;line-height:13px;font-family:Helvetica;color:#000000;}\n\t.ft611{font-size:10px;line-height:13px;font-family:Helvetica;color:#000000;}\n" }} />
        <div id="page6-div" style={{position: 'relative', width: '918px', height: '1188px'}}>
          <img width={918} height={1188} src="target006.png" alt="background image" />
          <p style={{position: 'absolute', top: '34px', left: '720px', whiteSpace: 'nowrap'}} className="ft60"><b>Protected B&nbsp;</b></p>
          <p style={{position: 'absolute', top: '36px', left: '799px', whiteSpace: 'nowrap'}} className="ft61">when completed</p>
          <p style={{position: 'absolute', top: '47px', left: '63px', whiteSpace: 'nowrap'}} className="ft62"><b>Small business deduction</b></p>
          <p style={{position: 'absolute', top: '77px', left: '41px', whiteSpace: 'nowrap'}} className="ft63"><b>Canadian-controlled private corporations (CCPCs) throughout the tax year</b></p>
          <p style={{position: 'absolute', top: '90px', left: '719px', whiteSpace: 'nowrap'}} className="ft64"><b>400</b></p>
          <p style={{position: 'absolute', top: '91px', left: '864px', whiteSpace: 'nowrap'}} className="ft61">A</p>
          <p style={{position: 'absolute', top: '95px', left: '41px', whiteSpace: 'nowrap'}} className="ft61">Income eligible for the small business deduction from Schedule 7&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '122px', left: '719px', whiteSpace: 'nowrap'}} className="ft64"><b>405</b></p>
          <p style={{position: 'absolute', top: '122px', left: '864px', whiteSpace: 'nowrap'}} className="ft61">B</p>
          <p style={{position: 'absolute', top: '113px', left: '41px', whiteSpace: 'nowrap'}} className="ft61">Taxable income from line 360 on page 3,&nbsp;<b>minus</b>&nbsp;100/28 of the amount on line 632<b>*</b></p>
          <p style={{position: 'absolute', top: '113px', left: '41px', whiteSpace: 'nowrap'}} className="ft610">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; on page 8,&nbsp;<b>minus</b>&nbsp;4 times the amount on&nbsp;<br />line 636<b>**</b></p>
          <p style={{position: 'absolute', top: '126px', left: '41px', whiteSpace: 'nowrap'}} className="ft61">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;on page 8, and&nbsp;<b>minus</b>&nbsp;any amount that, because of federal law, is exempt from Part I tax&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '140px', left: '719px', whiteSpace: 'nowrap'}} className="ft64"><b>410</b></p>
          <p style={{position: 'absolute', top: '140px', left: '864px', whiteSpace: 'nowrap'}} className="ft61">C</p>
          <p style={{position: 'absolute', top: '145px', left: '41px', whiteSpace: 'nowrap'}} className="ft61">Business limit (see notes&nbsp;</p>
          <p style={{position: 'absolute', top: '144px', left: '177px', whiteSpace: 'nowrap'}} className="ft65"><b>1</b></p>
          <p style={{position: 'absolute', top: '145px', left: '41px', whiteSpace: 'nowrap'}} className="ft61">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; and&nbsp;</p>
          <p style={{position: 'absolute', top: '144px', left: '211px', whiteSpace: 'nowrap'}} className="ft65"><b>2</b></p>
          <p style={{position: 'absolute', top: '145px', left: '216px', whiteSpace: 'nowrap'}} className="ft61">&nbsp;below)&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '163px', left: '41px', whiteSpace: 'nowrap'}} className="ft611"><b>Notes:<br /></b>1.&nbsp;For CCPCs that are not associated, enter $500,000 on line 410. However, if the corporation's tax year is less than 51 weeks,&nbsp;</p>
          <p style={{position: 'absolute', top: '189px', left: '59px', whiteSpace: 'nowrap'}} className="ft61">prorate this amount by the number of days in the tax year<b>&nbsp;divided&nbsp;</b>by 365, and enter the result on line 410.</p>
          <p style={{position: 'absolute', top: '208px', left: '41px', whiteSpace: 'nowrap'}} className="ft61">2.&nbsp;For associated CCPCs, use Schedule 23 to calculate the amount to be entered on line 410.&nbsp;</p>
          <p style={{position: 'absolute', top: '230px', left: '41px', whiteSpace: 'nowrap'}} className="ft63"><b>Business limit reduction</b></p>
          <p style={{position: 'absolute', top: '248px', left: '59px', whiteSpace: 'nowrap'}} className="ft63"><b>Taxable capital business limit reduction for tax years starting before April 7, 2022</b></p>
          <p style={{position: 'absolute', top: '275px', left: '684px', whiteSpace: 'nowrap'}} className="ft61">E1</p>
          <p style={{position: 'absolute', top: '275px', left: '59px', whiteSpace: 'nowrap'}} className="ft61">Amount C</p>
          <p style={{position: 'absolute', top: '272px', left: '240px', whiteSpace: 'nowrap'}} className="ft66">×</p>
          <p style={{position: 'absolute', top: '275px', left: '260px', whiteSpace: 'nowrap'}} className="ft64"><b>415</b></p>
          <p style={{position: 'absolute', top: '275px', left: '419px', whiteSpace: 'nowrap'}} className="ft61">D</p>
          <p style={{position: 'absolute', top: '275px', left: '288px', whiteSpace: 'nowrap'}} className="ft63"><b>***</b></p>
          <p style={{position: 'absolute', top: '293px', left: '342px', whiteSpace: 'nowrap'}} className="ft61">11,250</p>
          <p style={{position: 'absolute', top: '272px', left: '452px', whiteSpace: 'nowrap'}} className="ft66">=</p>
          <p style={{position: 'absolute', top: '279px', left: '473px', whiteSpace: 'nowrap'}} className="ft61">.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '306px', left: '58px', whiteSpace: 'nowrap'}} className="ft63"><b>Taxable capital business limit reduction for tax years starting after April 6, 2022</b></p>
          <p style={{position: 'absolute', top: '333px', left: '684px', whiteSpace: 'nowrap'}} className="ft61">E2</p>
          <p style={{position: 'absolute', top: '334px', left: '58px', whiteSpace: 'nowrap'}} className="ft61">Amount C</p>
          <p style={{position: 'absolute', top: '331px', left: '240px', whiteSpace: 'nowrap'}} className="ft66">×</p>
          <p style={{position: 'absolute', top: '333px', left: '260px', whiteSpace: 'nowrap'}} className="ft64"><b>415</b></p>
          <p style={{position: 'absolute', top: '334px', left: '419px', whiteSpace: 'nowrap'}} className="ft61">D</p>
          <p style={{position: 'absolute', top: '334px', left: '288px', whiteSpace: 'nowrap'}} className="ft63"><b>***</b></p>
          <p style={{position: 'absolute', top: '351px', left: '342px', whiteSpace: 'nowrap'}} className="ft61">90,000</p>
          <p style={{position: 'absolute', top: '331px', left: '452px', whiteSpace: 'nowrap'}} className="ft66">=</p>
          <p style={{position: 'absolute', top: '338px', left: '473px', whiteSpace: 'nowrap'}} className="ft61">.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '369px', left: '864px', whiteSpace: 'nowrap'}} className="ft61">E3</p>
          <p style={{position: 'absolute', top: '374px', left: '326px', whiteSpace: 'nowrap'}} className="ft61">Amount E1 or amount E2, whichever applies</p>
          <p style={{position: 'absolute', top: '370px', left: '713px', whiteSpace: 'nowrap'}} className="ft67">Ź</p>
          <p style={{position: 'absolute', top: '397px', left: '58px', whiteSpace: 'nowrap'}} className="ft63"><b>Passive income business limit reduction</b></p>
          <p style={{position: 'absolute', top: '410px', left: '864px', whiteSpace: 'nowrap'}} className="ft61">F</p>
          <p style={{position: 'absolute', top: '410px', left: '489px', whiteSpace: 'nowrap'}} className="ft64"><b>417</b></p>
          <p style={{position: 'absolute', top: '414px', left: '59px', whiteSpace: 'nowrap'}} className="ft61">Adjusted aggregate investment income from Schedule 7<b>****&nbsp;</b>.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '407px', left: '637px', whiteSpace: 'nowrap'}} className="ft66">–</p>
          <p style={{position: 'absolute', top: '410px', left: '657px', whiteSpace: 'nowrap'}} className="ft61">50,000</p>
          <p style={{position: 'absolute', top: '407px', left: '699px', whiteSpace: 'nowrap'}} className="ft66">=</p>
          <p style={{position: 'absolute', top: '414px', left: '716px', whiteSpace: 'nowrap'}} className="ft61">.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '442px', left: '864px', whiteSpace: 'nowrap'}} className="ft61">G</p>
          <p style={{position: 'absolute', top: '442px', left: '59px', whiteSpace: 'nowrap'}} className="ft61">Amount C</p>
          <p style={{position: 'absolute', top: '460px', left: '154px', whiteSpace: 'nowrap'}} className="ft61">100,000</p>
          <p style={{position: 'absolute', top: '439px', left: '240px', whiteSpace: 'nowrap'}} className="ft66">×</p>
          <p style={{position: 'absolute', top: '442px', left: '257px', whiteSpace: 'nowrap'}} className="ft61">Amount F</p>
          <p style={{position: 'absolute', top: '439px', left: '443px', whiteSpace: 'nowrap'}} className="ft66">=</p>
          <p style={{position: 'absolute', top: '446px', left: '473px', whiteSpace: 'nowrap'}} className="ft61">.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '473px', left: '719px', whiteSpace: 'nowrap'}} className="ft64"><b>422</b></p>
          <p style={{position: 'absolute', top: '473px', left: '864px', whiteSpace: 'nowrap'}} className="ft61">H</p>
          <p style={{position: 'absolute', top: '478px', left: '486px', whiteSpace: 'nowrap'}} className="ft61">The greater of amount E3 and amount G</p>
          <p style={{position: 'absolute', top: '495px', left: '719px', whiteSpace: 'nowrap'}} className="ft64"><b>426</b></p>
          <p style={{position: 'absolute', top: '495px', left: '864px', whiteSpace: 'nowrap'}} className="ft61">I</p>
          <p style={{position: 'absolute', top: '500px', left: '41px', whiteSpace: 'nowrap'}} className="ft61">Reduced business limit (amount C&nbsp;<b>minus</b>&nbsp;amount H) (if negative, enter "0")&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '518px', left: '864px', whiteSpace: 'nowrap'}} className="ft61">J</p>
          <p style={{position: 'absolute', top: '522px', left: '41px', whiteSpace: 'nowrap'}} className="ft61">Business limit the CCPC assigns under subsection 125(3.2) (from line 515 below)&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '540px', left: '719px', whiteSpace: 'nowrap'}} className="ft64"><b>428</b></p>
          <p style={{position: 'absolute', top: '540px', left: '864px', whiteSpace: 'nowrap'}} className="ft61">K</p>
          <p style={{position: 'absolute', top: '545px', left: '41px', whiteSpace: 'nowrap'}} className="ft63"><b>Reduced business limit after assignment&nbsp;</b>(amount I&nbsp;<b>minus&nbsp;</b>amount J)&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '563px', left: '719px', whiteSpace: 'nowrap'}} className="ft64"><b>430</b></p>
          <p style={{position: 'absolute', top: '567px', left: '41px', whiteSpace: 'nowrap'}} className="ft63"><b>Small business deduction –</b>&nbsp;Amount A, B, C, or K, whichever is the least&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '560px', left: '636px', whiteSpace: 'nowrap'}} className="ft66">×</p>
          <p style={{position: 'absolute', top: '562px', left: '658px', whiteSpace: 'nowrap'}} className="ft61">19%</p>
          <p style={{position: 'absolute', top: '560px', left: '690px', whiteSpace: 'nowrap'}} className="ft66">=</p>
          <p style={{position: 'absolute', top: '581px', left: '41px', whiteSpace: 'nowrap'}} className="ft61">Enter amount from line 430 at amount K on page 8.</p>
          <p style={{position: 'absolute', top: '649px', left: '41px', whiteSpace: 'nowrap'}} className="ft61">*</p>
          <p style={{position: 'absolute', top: '644px', left: '59px', whiteSpace: 'nowrap'}} className="ft610">Calculate the amount of foreign non-business income tax credit deductible on line 632 without reference to the refundable tax on the CCPC's investment&nbsp;<br />income (line 604) and without reference to the corporate tax reductions under section 123.4.</p>
          <p style={{position: 'absolute', top: '680px', left: '41px', whiteSpace: 'nowrap'}} className="ft61">**&nbsp;Calculate the amount of foreign business income tax credit deductible on line 636 without reference to the corporation tax reductions under section 123.4.</p>
          <p style={{position: 'absolute', top: '698px', left: '41px', whiteSpace: 'nowrap'}} className="ft61">***&nbsp;<b>Large corporations</b></p>
          <p style={{position: 'absolute', top: '716px', left: '60px', whiteSpace: 'nowrap'}} className="ft68">•</p>
          <p style={{position: 'absolute', top: '716px', left: '72px', whiteSpace: 'nowrap'}} className="ft610">If the corporation is not associated with any corporations in both the current and previous tax years, the amount to be entered&nbsp;on line 415 is: (total&nbsp;<br />taxable capital employed in Canada for the&nbsp;<b>prior</b>&nbsp;year&nbsp;<b>minus</b>&nbsp;$10,000,000) x 0.225%.</p>
          <p style={{position: 'absolute', top: '743px', left: '60px', whiteSpace: 'nowrap'}} className="ft68">•</p>
          <p style={{position: 'absolute', top: '743px', left: '72px', whiteSpace: 'nowrap'}} className="ft610">If the corporation is not associated with any corporations in the current tax year, but was associated in the previous tax year, the amount to be entered&nbsp;<br />on line 415 is: (total taxable capital employed in Canada for the&nbsp;<b>current</b>&nbsp;year&nbsp;<b>minus</b>&nbsp;$10,000,000) x 0.225%.</p>
          <p style={{position: 'absolute', top: '770px', left: '60px', whiteSpace: 'nowrap'}} className="ft68">•</p>
          <p style={{position: 'absolute', top: '770px', left: '72px', whiteSpace: 'nowrap'}} className="ft61">For corporations associated in the current tax year, see Schedule 23 for the special rules that apply.</p>
          <p style={{position: 'absolute', top: '788px', left: '36px', whiteSpace: 'nowrap'}} className="ft61">****&nbsp;Enter the total adjusted aggregate investment income of the corporation and all associated corporations for each tax year that&nbsp;ended in the preceding&nbsp;</p>
          <p style={{position: 'absolute', top: '801px', left: '58px', whiteSpace: 'nowrap'}} className="ft610">calendar year. Each corporation with such income has to file a Schedule 7. For a corporation's first tax year that starts after&nbsp;2018, this amount is&nbsp;<br />reported at line 744 of the corresponding Schedule 7. Otherwise, this amount is the total of all amounts reported at line 745 of the corresponding&nbsp;<br />Schedule 7 of the corporation for each tax year that ended in the preceding calendar year.</p>
          <p style={{position: 'absolute', top: '1145px', left: '31px', whiteSpace: 'nowrap'}} className="ft69">T2 E (23)</p>
          <p style={{position: 'absolute', top: '1145px', left: '833px', whiteSpace: 'nowrap'}} className="ft69">Page 4 of 9</p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\tp {margin: 0; padding: 0;}\t.ft70{font-size:11px;font-family:Helvetica;color:#000000;}\n\t.ft71{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft72{font-size:13px;font-family:Helvetica;color:#000000;}\n\t.ft73{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft74{font-size:10px;font-family:Helvetica;color:#ffffff;}\n\t.ft75{font-size:7px;font-family:Helvetica;color:#000000;}\n\t.ft76{font-size:13px;font-family:Times;color:#000000;}\n\t.ft77{font-size:8px;font-family:Helvetica;color:#000000;}\n\t.ft78{font-size:10px;line-height:13px;font-family:Helvetica;color:#000000;}\n\t.ft79{font-size:10px;line-height:13px;font-family:Helvetica;color:#000000;}\n\t.ft710{font-size:10px;line-height:14px;font-family:Helvetica;color:#000000;}\n" }} />
        <div id="page7-div" style={{position: 'relative', width: '918px', height: '1188px'}}>
          <img width={918} height={1188} src="target007.png" alt="background image" />
          <p style={{position: 'absolute', top: '34px', left: '720px', whiteSpace: 'nowrap'}} className="ft70"><b>Protected B&nbsp;</b></p>
          <p style={{position: 'absolute', top: '36px', left: '799px', whiteSpace: 'nowrap'}} className="ft71">when completed</p>
          <p style={{position: 'absolute', top: '65px', left: '65px', whiteSpace: 'nowrap'}} className="ft72"><b>Small business deduction (continued)</b></p>
          <p style={{position: 'absolute', top: '88px', left: '41px', whiteSpace: 'nowrap'}} className="ft73"><b>Specified corporate income and assignment under subsection 125(3.2)</b></p>
          <p style={{position: 'absolute', top: '202px', left: '136px', whiteSpace: 'nowrap'}} className="ft74"><b>490</b></p>
          <p style={{position: 'absolute', top: '112px', left: '143px', whiteSpace: 'nowrap'}} className="ft71">L</p>
          <p style={{position: 'absolute', top: '130px', left: '92px', whiteSpace: 'nowrap'}} className="ft71">&nbsp;Business number of&nbsp;</p>
          <p style={{position: 'absolute', top: '143px', left: '106px', whiteSpace: 'nowrap'}} className="ft71">the corporation&nbsp;</p>
          <p style={{position: 'absolute', top: '157px', left: '112px', whiteSpace: 'nowrap'}} className="ft71">receiving the&nbsp;</p>
          <p style={{position: 'absolute', top: '170px', left: '101px', whiteSpace: 'nowrap'}} className="ft71">assigned amount</p>
          <p style={{position: 'absolute', top: '202px', left: '316px', whiteSpace: 'nowrap'}} className="ft74"><b>500</b></p>
          <p style={{position: 'absolute', top: '112px', left: '319px', whiteSpace: 'nowrap'}} className="ft71">M</p>
          <p style={{position: 'absolute', top: '129px', left: '274px', whiteSpace: 'nowrap'}} className="ft71">Income paid under&nbsp;</p>
          <p style={{position: 'absolute', top: '143px', left: '250px', whiteSpace: 'nowrap'}} className="ft71">clause 125(1)(a)(i)(B) to the&nbsp;</p>
          <p style={{position: 'absolute', top: '156px', left: '262px', whiteSpace: 'nowrap'}} className="ft71">corporation identified in&nbsp;</p>
          <p style={{position: 'absolute', top: '170px', left: '297px', whiteSpace: 'nowrap'}} className="ft71">column L</p>
          <p style={{position: 'absolute', top: '170px', left: '346px', whiteSpace: 'nowrap'}} className="ft75"><b>3</b></p>
          <p style={{position: 'absolute', top: '202px', left: '492px', whiteSpace: 'nowrap'}} className="ft74"><b>505</b></p>
          <p style={{position: 'absolute', top: '112px', left: '505px', whiteSpace: 'nowrap'}} className="ft71">N</p>
          <p style={{position: 'absolute', top: '129px', left: '439px', whiteSpace: 'nowrap'}} className="ft71">&nbsp;Business limit assigned to&nbsp;</p>
          <p style={{position: 'absolute', top: '143px', left: '447px', whiteSpace: 'nowrap'}} className="ft71">corporation identified in&nbsp;</p>
          <p style={{position: 'absolute', top: '157px', left: '482px', whiteSpace: 'nowrap'}} className="ft71">column L</p>
          <p style={{position: 'absolute', top: '156px', left: '531px', whiteSpace: 'nowrap'}} className="ft75"><b>4</b></p>
          <p style={{position: 'absolute', top: '228px', left: '43px', whiteSpace: 'nowrap'}} className="ft71">1.</p>
          <p style={{position: 'absolute', top: '245px', left: '43px', whiteSpace: 'nowrap'}} className="ft71">2.</p>
          <p style={{position: 'absolute', top: '263px', left: '43px', whiteSpace: 'nowrap'}} className="ft71">3.</p>
          <p style={{position: 'absolute', top: '281px', left: '43px', whiteSpace: 'nowrap'}} className="ft71">4.</p>
          <p style={{position: 'absolute', top: '306px', left: '285px', whiteSpace: 'nowrap'}} className="ft74"><b>510</b></p>
          <p style={{position: 'absolute', top: '307px', left: '252px', whiteSpace: 'nowrap'}} className="ft71">Total</p>
          <p style={{position: 'absolute', top: '305px', left: '465px', whiteSpace: 'nowrap'}} className="ft74"><b>515</b></p>
          <p style={{position: 'absolute', top: '307px', left: '432px', whiteSpace: 'nowrap'}} className="ft71">Total</p>
          <p style={{position: 'absolute', top: '338px', left: '41px', whiteSpace: 'nowrap'}} className="ft78"><b>Notes:<br /></b>3.&nbsp;This amount is [as defined in subsection 125(7)&nbsp;<b>specified corporate income</b>&nbsp;(a)(i)] the total of all amounts each of which is income (other than&nbsp;</p>
          <p style={{position: 'absolute', top: '365px', left: '59px', whiteSpace: 'nowrap'}} className="ft79">specified farming or fishing income of the corporation for the year) from an active business of the corporation for the year from the provision of&nbsp;<br />services or property to a private corporation (directly or indirectly, in any manner whatever) if<br />(A) at any time in the year, the corporation (or one of its shareholders) or a person who does not deal at arm's length with the corporation (or one of its&nbsp;<br />shareholders) holds a direct or indirect interest in the private corporation, and<br />(B) it is not the case that all or substantially all of the corporation's income for the year from an active business is from the provision of services or&nbsp;<br />property to</p>
          <p style={{position: 'absolute', top: '446px', left: '72px', whiteSpace: 'nowrap'}} className="ft79">(I) persons (other than the private corporation) with which the corporation deals at arm's length, or<br />(II) partnerships with which the corporation deals at arm's length, other than a partnership in which a person that does not deal at arm's length&nbsp;<br />with the corporation holds a direct or indirect interest.</p>
          <p style={{position: 'absolute', top: '491px', left: '41px', whiteSpace: 'nowrap'}} className="ft71">4.&nbsp;The amount of the business limit you assign to a CCPC cannot be greater than the amount determined by the formula A – B, where&nbsp;A is the amount of&nbsp;</p>
          <p style={{position: 'absolute', top: '504px', left: '59px', whiteSpace: 'nowrap'}} className="ft79">income referred to in column M in respect of that CCPC and B is the portion of the amount described in A that is deductible by&nbsp;you in respect of the&nbsp;<br />amount of income referred to in clauses 125(1)(a)(i)(A) or (B) for the year. The amount on line 515 cannot be greater than the&nbsp;amount on line 426.</p>
          <p style={{position: 'absolute', top: '551px', left: '63px', whiteSpace: 'nowrap'}} className="ft72"><b>General tax reduction for Canadian-controlled private corporations</b></p>
          <p style={{position: 'absolute', top: '576px', left: '41px', whiteSpace: 'nowrap'}} className="ft73"><b>Canadian-controlled private corporations throughout the tax year</b></p>
          <p style={{position: 'absolute', top: '595px', left: '864px', whiteSpace: 'nowrap'}} className="ft71">A</p>
          <p style={{position: 'absolute', top: '599px', left: '41px', whiteSpace: 'nowrap'}} className="ft71">Taxable income from line 360 on page 3&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '631px', left: '689px', whiteSpace: 'nowrap'}} className="ft71">B</p>
          <p style={{position: 'absolute', top: '635px', left: '41px', whiteSpace: 'nowrap'}} className="ft71">Lesser of amounts 9B and 9H from Part 9 of Schedule 27&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '649px', left: '689px', whiteSpace: 'nowrap'}} className="ft71">C</p>
          <p style={{position: 'absolute', top: '651px', left: '41px', whiteSpace: 'nowrap'}} className="ft71">Amount 13K from Part 13 of Schedule 27&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '666px', left: '539px', whiteSpace: 'nowrap'}} className="ft74"><b>432</b></p>
          <p style={{position: 'absolute', top: '667px', left: '688px', whiteSpace: 'nowrap'}} className="ft71">D</p>
          <p style={{position: 'absolute', top: '671px', left: '40px', whiteSpace: 'nowrap'}} className="ft71">Personal services business income&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '685px', left: '689px', whiteSpace: 'nowrap'}} className="ft71">E</p>
          <p style={{position: 'absolute', top: '689px', left: '41px', whiteSpace: 'nowrap'}} className="ft71">Amount from line 400, 405, 410, or 428 on page 4, whichever is the least&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '703px', left: '689px', whiteSpace: 'nowrap'}} className="ft71">F</p>
          <p style={{position: 'absolute', top: '707px', left: '41px', whiteSpace: 'nowrap'}} className="ft71">Aggregate investment income from line 440 on page 6<b>*&nbsp;</b>.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '730px', left: '869px', whiteSpace: 'nowrap'}} className="ft71">G</p>
          <p style={{position: 'absolute', top: '730px', left: '401px', whiteSpace: 'nowrap'}} className="ft71">Subtotal (<b>add</b>&nbsp;amounts B to F)</p>
          <p style={{position: 'absolute', top: '725px', left: '690px', whiteSpace: 'nowrap'}} className="ft76">Ź</p>
          <p style={{position: 'absolute', top: '757px', left: '869px', whiteSpace: 'nowrap'}} className="ft71">H</p>
          <p style={{position: 'absolute', top: '761px', left: '41px', whiteSpace: 'nowrap'}} className="ft71">Amount A&nbsp;<b>minus</b>&nbsp;amount G (if negative, enter "0")&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '784px', left: '869px', whiteSpace: 'nowrap'}} className="ft71">I</p>
          <p style={{position: 'absolute', top: '788px', left: '41px', whiteSpace: 'nowrap'}} className="ft79"><b>General tax reduction for Canadian-controlled private corporations</b>&nbsp;– Amount H&nbsp;<b>multiplied</b>&nbsp;by 13%&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.<br />Enter amount I on line 638 on page 8.</p>
          <p style={{position: 'absolute', top: '829px', left: '41px', whiteSpace: 'nowrap'}} className="ft71">*&nbsp;Except for a corporation that is, throughout the year, a cooperative corporation (within the meaning assigned by subsection 136(2)) or a credit union.</p>
          <p style={{position: 'absolute', top: '879px', left: '63px', whiteSpace: 'nowrap'}} className="ft72"><b>General tax reduction</b></p>
          <p style={{position: 'absolute', top: '905px', left: '41px', whiteSpace: 'nowrap'}} className="ft78"><b>Do not complete this area if you are a Canadian-controlled private corporation, an investment corporation, a mortgage investment corporation,&nbsp;<br />a mutual fund corporation, or any corporation with taxable income that is not subject to the corporation tax rate of 38%.</b></p>
          <p style={{position: 'absolute', top: '946px', left: '869px', whiteSpace: 'nowrap'}} className="ft71">J</p>
          <p style={{position: 'absolute', top: '950px', left: '41px', whiteSpace: 'nowrap'}} className="ft71">Taxable income from line 360 on page 3&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '973px', left: '689px', whiteSpace: 'nowrap'}} className="ft71">K</p>
          <p style={{position: 'absolute', top: '977px', left: '41px', whiteSpace: 'nowrap'}} className="ft71">Lesser of amounts 9B and 9H from Part 9 of Schedule 27&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '991px', left: '689px', whiteSpace: 'nowrap'}} className="ft71">L</p>
          <p style={{position: 'absolute', top: '993px', left: '41px', whiteSpace: 'nowrap'}} className="ft71">Amount 13K from Part 13 of Schedule 27&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1008px', left: '539px', whiteSpace: 'nowrap'}} className="ft74"><b>434</b></p>
          <p style={{position: 'absolute', top: '1009px', left: '688px', whiteSpace: 'nowrap'}} className="ft71">M</p>
          <p style={{position: 'absolute', top: '1013px', left: '40px', whiteSpace: 'nowrap'}} className="ft71">Personal services business income&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1036px', left: '869px', whiteSpace: 'nowrap'}} className="ft71">N</p>
          <p style={{position: 'absolute', top: '1036px', left: '398px', whiteSpace: 'nowrap'}} className="ft71">Subtotal (<b>add</b>&nbsp;amounts K to M)</p>
          <p style={{position: 'absolute', top: '1031px', left: '690px', whiteSpace: 'nowrap'}} className="ft76">Ź</p>
          <p style={{position: 'absolute', top: '1067px', left: '869px', whiteSpace: 'nowrap'}} className="ft71">O</p>
          <p style={{position: 'absolute', top: '1072px', left: '41px', whiteSpace: 'nowrap'}} className="ft71">Amount J&nbsp;<b>minus</b>&nbsp;amount N (if negative, enter "0")&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1094px', left: '869px', whiteSpace: 'nowrap'}} className="ft71">P</p>
          <p style={{position: 'absolute', top: '1099px', left: '41px', whiteSpace: 'nowrap'}} className="ft79"><b>General tax reduction</b>&nbsp;– Amount O&nbsp;<b>multiplied</b>&nbsp;by 13%&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.<br />Enter amount P on line 639 on page 8.</p>
          <p style={{position: 'absolute', top: '1145px', left: '31px', whiteSpace: 'nowrap'}} className="ft77">T2 E (23)</p>
          <p style={{position: 'absolute', top: '1145px', left: '833px', whiteSpace: 'nowrap'}} className="ft77">Page 5 of 9</p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\tp {margin: 0; padding: 0;}\t.ft80{font-size:11px;font-family:Helvetica;color:#000000;}\n\t.ft81{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft82{font-size:13px;font-family:Helvetica;color:#000000;}\n\t.ft83{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft84{font-size:10px;font-family:Helvetica;color:#ffffff;}\n\t.ft85{font-size:14px;font-family:Helvetica;color:#000000;}\n\t.ft86{font-size:12px;font-family:Times;color:#000000;-moz-transform: matrix(        -1,          0,          0,         -1, 0, 0);-webkit-transform: matrix(        -1,          0,          0,         -1, 0, 0);-o-transform: matrix(        -1,          0,          0,         -1, 0, 0);-ms-transform: matrix(        -1,          0,          0,         -1, 0, 0);-moz-transform-origin: left 75%;-webkit-transform-origin: left 75%;-o-transform-origin: left 75%;-ms-transform-origin: left 75%;}\n\t.ft87{font-size:8px;font-family:Helvetica;color:#000000;}\n\t.ft88{font-size:10px;line-height:13px;font-family:Helvetica;color:#000000;}\n" }} />
        <div id="page8-div" style={{position: 'relative', width: '918px', height: '1188px'}}>
          <img width={918} height={1188} src="target008.png" alt="background image" />
          <p style={{position: 'absolute', top: '34px', left: '720px', whiteSpace: 'nowrap'}} className="ft80"><b>Protected B&nbsp;</b></p>
          <p style={{position: 'absolute', top: '36px', left: '799px', whiteSpace: 'nowrap'}} className="ft81">when completed</p>
          <p style={{position: 'absolute', top: '42px', left: '64px', whiteSpace: 'nowrap'}} className="ft82"><b>Refundable portion of Part I tax</b></p>
          <p style={{position: 'absolute', top: '79px', left: '45px', whiteSpace: 'nowrap'}} className="ft83"><b>Canadian-controlled private corporations throughout the tax year</b></p>
          <p style={{position: 'absolute', top: '116px', left: '873px', whiteSpace: 'nowrap'}} className="ft81">A</p>
          <p style={{position: 'absolute', top: '116px', left: '240px', whiteSpace: 'nowrap'}} className="ft84"><b>440</b></p>
          <p style={{position: 'absolute', top: '108px', left: '45px', whiteSpace: 'nowrap'}} className="ft88">Aggregate investment income&nbsp;<br />from Schedule 7&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '118px', left: '401px', whiteSpace: 'nowrap'}} className="ft85">×</p>
          <p style={{position: 'absolute', top: '120px', left: '417px', whiteSpace: 'nowrap'}} className="ft81">30 2/3%</p>
          <p style={{position: 'absolute', top: '119px', left: '463px', whiteSpace: 'nowrap'}} className="ft85">=</p>
          <p style={{position: 'absolute', top: '122px', left: '479px', whiteSpace: 'nowrap'}} className="ft81">.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '148px', left: '666px', whiteSpace: 'nowrap'}} className="ft81">B</p>
          <p style={{position: 'absolute', top: '155px', left: '45px', whiteSpace: 'nowrap'}} className="ft81">Foreign non-business income tax credit from line 632 on page 8.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '179px', left: '666px', whiteSpace: 'nowrap'}} className="ft81">C</p>
          <p style={{position: 'absolute', top: '179px', left: '240px', whiteSpace: 'nowrap'}} className="ft84"><b>445</b></p>
          <p style={{position: 'absolute', top: '173px', left: '45px', whiteSpace: 'nowrap'}} className="ft88">Foreign investment income&nbsp;<br />from Schedule 7&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '181px', left: '405px', whiteSpace: 'nowrap'}} className="ft85">×</p>
          <p style={{position: 'absolute', top: '184px', left: '430px', whiteSpace: 'nowrap'}} className="ft81">8%</p>
          <p style={{position: 'absolute', top: '182px', left: '463px', whiteSpace: 'nowrap'}} className="ft85">=</p>
          <p style={{position: 'absolute', top: '185px', left: '479px', whiteSpace: 'nowrap'}} className="ft81">.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '202px', left: '873px', whiteSpace: 'nowrap'}} className="ft81">D</p>
          <p style={{position: 'absolute', top: '205px', left: '212px', whiteSpace: 'nowrap'}} className="ft81">Subtotal (amount B&nbsp;<b>minus</b>&nbsp;amount C) (if negative, enter "0")</p>
          <p style={{position: 'absolute', top: '192px', left: '680px', whiteSpace: 'nowrap'}} className="ft86">Ż</p>
          <p style={{position: 'absolute', top: '234px', left: '873px', whiteSpace: 'nowrap'}} className="ft81">E</p>
          <p style={{position: 'absolute', top: '243px', left: '45px', whiteSpace: 'nowrap'}} className="ft81">Amount A&nbsp;<b>minus</b>&nbsp;amount D (if negative, enter "0").&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '274px', left: '666px', whiteSpace: 'nowrap'}} className="ft81">F</p>
          <p style={{position: 'absolute', top: '281px', left: '45px', whiteSpace: 'nowrap'}} className="ft81">Taxable income from line 360 on page 3&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '337px', left: '517px', whiteSpace: 'nowrap'}} className="ft81">G</p>
          <p style={{position: 'absolute', top: '329px', left: '58px', whiteSpace: 'nowrap'}} className="ft88">Amount from line 400, 405, 410, or 428 on page 4,&nbsp;<br />whichever is the least&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '386px', left: '518px', whiteSpace: 'nowrap'}} className="ft81">H</p>
          <p style={{position: 'absolute', top: '365px', left: '59px', whiteSpace: 'nowrap'}} className="ft88">Foreign non-business&nbsp;<br />income tax credit from&nbsp;<br />line 632 on page 8&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '386px', left: '306px', whiteSpace: 'nowrap'}} className="ft85">×</p>
          <p style={{position: 'absolute', top: '388px', left: '320px', whiteSpace: 'nowrap'}} className="ft81">75/29</p>
          <p style={{position: 'absolute', top: '386px', left: '364px', whiteSpace: 'nowrap'}} className="ft85">=</p>
          <p style={{position: 'absolute', top: '443px', left: '518px', whiteSpace: 'nowrap'}} className="ft81">I</p>
          <p style={{position: 'absolute', top: '419px', left: '59px', whiteSpace: 'nowrap'}} className="ft88">Foreign business income&nbsp;<br />tax credit from line 636&nbsp;<br />on page 8&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '442px', left: '306px', whiteSpace: 'nowrap'}} className="ft85">×</p>
          <p style={{position: 'absolute', top: '443px', left: '327px', whiteSpace: 'nowrap'}} className="ft81">4</p>
          <p style={{position: 'absolute', top: '442px', left: '348px', whiteSpace: 'nowrap'}} className="ft85">=</p>
          <p style={{position: 'absolute', top: '446px', left: '365px', whiteSpace: 'nowrap'}} className="ft81">.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '472px', left: '666px', whiteSpace: 'nowrap'}} className="ft81">J</p>
          <p style={{position: 'absolute', top: '475px', left: '228px', whiteSpace: 'nowrap'}} className="ft81">Subtotal (<b>add&nbsp;</b>amounts G to I)</p>
          <p style={{position: 'absolute', top: '462px', left: '536px', whiteSpace: 'nowrap'}} className="ft86">Ż</p>
          <p style={{position: 'absolute', top: '514px', left: '873px', whiteSpace: 'nowrap'}} className="ft81">L</p>
          <p style={{position: 'absolute', top: '514px', left: '666px', whiteSpace: 'nowrap'}} className="ft81">K</p>
          <p style={{position: 'absolute', top: '514px', left: '336px', whiteSpace: 'nowrap'}} className="ft81">Subtotal (amount F&nbsp;<b>minus</b>&nbsp;amount J)</p>
          <p style={{position: 'absolute', top: '512px', left: '677px', whiteSpace: 'nowrap'}} className="ft85">×</p>
          <p style={{position: 'absolute', top: '514px', left: '690px', whiteSpace: 'nowrap'}} className="ft81">30</p>
          <p style={{position: 'absolute', top: '515px', left: '707px', whiteSpace: 'nowrap'}} className="ft87">2/3</p>
          <p style={{position: 'absolute', top: '514px', left: '721px', whiteSpace: 'nowrap'}} className="ft81">%</p>
          <p style={{position: 'absolute', top: '512px', left: '738px', whiteSpace: 'nowrap'}} className="ft85">=</p>
          <p style={{position: 'absolute', top: '553px', left: '873px', whiteSpace: 'nowrap'}} className="ft81">M</p>
          <p style={{position: 'absolute', top: '559px', left: '45px', whiteSpace: 'nowrap'}} className="ft81">Part I tax payable minus investment tax credit refund (line 700&nbsp;<b>minus</b>&nbsp;line 780 from page 9)&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '589px', left: '721px', whiteSpace: 'nowrap'}} className="ft84"><b>450</b></p>
          <p style={{position: 'absolute', top: '589px', left: '873px', whiteSpace: 'nowrap'}} className="ft81">N</p>
          <p style={{position: 'absolute', top: '595px', left: '45px', whiteSpace: 'nowrap'}} className="ft83"><b>Refundable portion of Part I tax&nbsp;</b>– Amount E, L, or M, whichever is the least&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1145px', left: '31px', whiteSpace: 'nowrap'}} className="ft87">T2 E (23)</p>
          <p style={{position: 'absolute', top: '1145px', left: '833px', whiteSpace: 'nowrap'}} className="ft87">Page 6 of 9</p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\tp {margin: 0; padding: 0;}\t.ft90{font-size:11px;font-family:Helvetica;color:#000000;}\n\t.ft91{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft92{font-size:13px;font-family:Helvetica;color:#000000;}\n\t.ft93{font-size:10px;font-family:Helvetica;color:#ffffff;}\n\t.ft94{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft95{font-size:13px;font-family:Times;color:#000000;}\n\t.ft96{font-size:8px;font-family:Helvetica;color:#000000;}\n\t.ft97{font-size:10px;line-height:13px;font-family:Helvetica;color:#000000;}\n\t.ft98{font-size:10px;line-height:16px;font-family:Helvetica;color:#000000;}\n" }} />
        <div id="page9-div" style={{position: 'relative', width: '918px', height: '1188px'}}>
          <img width={918} height={1188} src="target009.png" alt="background image" />
          <p style={{position: 'absolute', top: '34px', left: '718px', whiteSpace: 'nowrap'}} className="ft90"><b>Protected B&nbsp;</b></p>
          <p style={{position: 'absolute', top: '35px', left: '797px', whiteSpace: 'nowrap'}} className="ft91">when completed</p>
          <p style={{position: 'absolute', top: '47px', left: '64px', whiteSpace: 'nowrap'}} className="ft92"><b>Refundable dividend tax on hand</b></p>
          <p style={{position: 'absolute', top: '76px', left: '714px', whiteSpace: 'nowrap'}} className="ft93"><b>520</b></p>
          <p style={{position: 'absolute', top: '75px', left: '868px', whiteSpace: 'nowrap'}} className="ft91">A</p>
          <p style={{position: 'absolute', top: '81px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">Eligible refundable dividend tax on hand (ERDTOH) at the end of the previous tax year (line 530 of the preceding tax year)&nbsp;</p>
          <p style={{position: 'absolute', top: '116px', left: '714px', whiteSpace: 'nowrap'}} className="ft93"><b>535</b></p>
          <p style={{position: 'absolute', top: '115px', left: '868px', whiteSpace: 'nowrap'}} className="ft91">B</p>
          <p style={{position: 'absolute', top: '108px', left: '45px', whiteSpace: 'nowrap'}} className="ft97">Non-eligible refundable dividend tax on hand (NERDTOH) at the end of the previous tax year (line 545 of the preceding tax&nbsp;<br />year) (if negative, enter "0")&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '147px', left: '724px', whiteSpace: 'nowrap'}} className="ft91">C</p>
          <p style={{position: 'absolute', top: '149px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">Part IV tax payable on taxable dividends from connected corporations (amount 2G from Schedule 3)</p>
          <p style={{position: 'absolute', top: '169px', left: '724px', whiteSpace: 'nowrap'}} className="ft91">D</p>
          <p style={{position: 'absolute', top: '172px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">Part IV tax payable on eligible dividends from non-connected corporations (amount 2J from Schedule 3)</p>
          <p style={{position: 'absolute', top: '187px', left: '869px', whiteSpace: 'nowrap'}} className="ft91">E</p>
          <p style={{position: 'absolute', top: '191px', left: '401px', whiteSpace: 'nowrap'}} className="ft91">Subtotal (amount C&nbsp;<b>plus</b>&nbsp;amount D)</p>
          <p style={{position: 'absolute', top: '187px', left: '726px', whiteSpace: 'nowrap'}} className="ft95">Ź</p>
          <p style={{position: 'absolute', top: '215px', left: '714px', whiteSpace: 'nowrap'}} className="ft93"><b>525</b></p>
          <p style={{position: 'absolute', top: '214px', left: '868px', whiteSpace: 'nowrap'}} className="ft91">F</p>
          <p style={{position: 'absolute', top: '221px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">Net ERDTOH transferred on an amalgamation or the wind-up of a subsidiary.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '238px', left: '714px', whiteSpace: 'nowrap'}} className="ft93"><b>570</b></p>
          <p style={{position: 'absolute', top: '237px', left: '868px', whiteSpace: 'nowrap'}} className="ft91">G</p>
          <p style={{position: 'absolute', top: '243px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">ERDTOH dividend refund for the previous tax year&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '264px', left: '869px', whiteSpace: 'nowrap'}} className="ft91">H</p>
          <p style={{position: 'absolute', top: '266px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">Refundable portion of Part I tax (from line 450 on page 6)&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '286px', left: '724px', whiteSpace: 'nowrap'}} className="ft91">I</p>
          <p style={{position: 'absolute', top: '293px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">Part IV tax before deductions (amount 2A from Schedule 3)&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '313px', left: '724px', whiteSpace: 'nowrap'}} className="ft91">J</p>
          <p style={{position: 'absolute', top: '315px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">Part IV tax allocated to ERDTOH (amount E).&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '336px', left: '724px', whiteSpace: 'nowrap'}} className="ft91">K</p>
          <p style={{position: 'absolute', top: '338px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">Part IV tax reduction due to Part IV.1 tax payable (amount 4D of Schedule 43).&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '354px', left: '869px', whiteSpace: 'nowrap'}} className="ft91">L</p>
          <p style={{position: 'absolute', top: '358px', left: '318px', whiteSpace: 'nowrap'}} className="ft91">Subtotal (amount I&nbsp;<b>minus</b>&nbsp;total of amounts J and K)</p>
          <p style={{position: 'absolute', top: '354px', left: '726px', whiteSpace: 'nowrap'}} className="ft95">Ź</p>
          <p style={{position: 'absolute', top: '382px', left: '714px', whiteSpace: 'nowrap'}} className="ft93"><b>540</b></p>
          <p style={{position: 'absolute', top: '381px', left: '868px', whiteSpace: 'nowrap'}} className="ft91">M</p>
          <p style={{position: 'absolute', top: '388px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">Net NERDTOH transferred on an amalgamation or the wind-up of a subsidiary&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '404px', left: '714px', whiteSpace: 'nowrap'}} className="ft93"><b>575</b></p>
          <p style={{position: 'absolute', top: '403px', left: '868px', whiteSpace: 'nowrap'}} className="ft91">N</p>
          <p style={{position: 'absolute', top: '410px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">NERDTOH dividend refund for the previous tax year&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '426px', left: '869px', whiteSpace: 'nowrap'}} className="ft91">O</p>
          <p style={{position: 'absolute', top: '428px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">38 1/3% of the total losses applied against Part IV tax (amount 2D from Schedule 3)&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '448px', left: '869px', whiteSpace: 'nowrap'}} className="ft91">P</p>
          <p style={{position: 'absolute', top: '451px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">Part IV tax payable allocated to NERDTOH, net of losses claimed (amount L&nbsp;<b>minus</b>&nbsp;amount O) (if negative enter "0")&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '467px', left: '714px', whiteSpace: 'nowrap'}} className="ft93"><b>545</b></p>
          <p style={{position: 'absolute', top: '473px', left: '45px', whiteSpace: 'nowrap'}} className="ft94"><b>NERDTOH at the end of the tax year</b>&nbsp;(total of amounts B, H, M, and P<b>&nbsp;minus</b>&nbsp;amount N) (if negative, enter "0")&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '498px', left: '869px', whiteSpace: 'nowrap'}} className="ft91">Q</p>
          <p style={{position: 'absolute', top: '491px', left: '45px', whiteSpace: 'nowrap'}} className="ft97">Part IV tax payable allocated to ERDTOH, net of losses claimed (amount E&nbsp;<b>minus</b>&nbsp;the amount, if any, by which amount O&nbsp;<br />exceeds amount L) (if negative, enter "0")&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '521px', left: '714px', whiteSpace: 'nowrap'}} className="ft93"><b>530</b></p>
          <p style={{position: 'absolute', top: '527px', left: '45px', whiteSpace: 'nowrap'}} className="ft94"><b>ERDTOH at the end of the tax year</b>&nbsp;(total of amounts A, F, and Q&nbsp;<b>minus</b>&nbsp;amount G) (if negative, enter "0")&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '587px', left: '64px', whiteSpace: 'nowrap'}} className="ft92"><b>Dividend refund</b></p>
          <p style={{position: 'absolute', top: '607px', left: '864px', whiteSpace: 'nowrap'}} className="ft91">AA</p>
          <p style={{position: 'absolute', top: '612px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">38 1/3% of total eligible dividends paid in the tax year (amount 3A from Schedule 3)&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '629px', left: '864px', whiteSpace: 'nowrap'}} className="ft91">BB</p>
          <p style={{position: 'absolute', top: '635px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">ERDTOH balance at the end of the tax year (line 530)&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '652px', left: '864px', whiteSpace: 'nowrap'}} className="ft91">CC</p>
          <p style={{position: 'absolute', top: '657px', left: '45px', whiteSpace: 'nowrap'}} className="ft94"><b>Eligible dividend refund</b>&nbsp;(amount AA or BB, whichever is less)&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '679px', left: '864px', whiteSpace: 'nowrap'}} className="ft91">DD</p>
          <p style={{position: 'absolute', top: '684px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">38 1/3% of total non-eligible taxable dividends paid in the tax year (amount 3B from Schedule 3)&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '701px', left: '864px', whiteSpace: 'nowrap'}} className="ft91">EE</p>
          <p style={{position: 'absolute', top: '707px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">NERDTOH balance at the end of the tax year (line 545)&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '724px', left: '864px', whiteSpace: 'nowrap'}} className="ft91">FF</p>
          <p style={{position: 'absolute', top: '729px', left: '45px', whiteSpace: 'nowrap'}} className="ft94"><b>Non-eligible dividend refund</b>&nbsp;(amount DD or EE, whichever is less)&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '751px', left: '864px', whiteSpace: 'nowrap'}} className="ft91">GG</p>
          <p style={{position: 'absolute', top: '756px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">Amount DD&nbsp;<b>minus</b>&nbsp;amount EE (if negative, enter "0")&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '773px', left: '864px', whiteSpace: 'nowrap'}} className="ft91">HH</p>
          <p style={{position: 'absolute', top: '779px', left: '45px', whiteSpace: 'nowrap'}} className="ft91">Amount BB&nbsp;<b>minus</b>&nbsp;amount CC (if negative, enter "0")&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '796px', left: '870px', whiteSpace: 'nowrap'}} className="ft91">II</p>
          <p style={{position: 'absolute', top: '801px', left: '45px', whiteSpace: 'nowrap'}} className="ft94"><b>Additional non-eligible dividend refund</b>&nbsp;(amount GG or HH, whichever is less)&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '823px', left: '867px', whiteSpace: 'nowrap'}} className="ft91">JJ</p>
          <p style={{position: 'absolute', top: '828px', left: '45px', whiteSpace: 'nowrap'}} className="ft98"><b>Dividend refund</b>&nbsp;– Amount CC&nbsp;<b>plus</b>&nbsp;amount FF&nbsp;<b>plus</b>&nbsp;amount II&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.<br />Enter amount JJ on line 784 on page 9.</p>
          <p style={{position: 'absolute', top: '1145px', left: '31px', whiteSpace: 'nowrap'}} className="ft96">T2 E (23)</p>
          <p style={{position: 'absolute', top: '1145px', left: '833px', whiteSpace: 'nowrap'}} className="ft96">Page 7 of 9</p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\tp {margin: 0; padding: 0;}\t.ft100{font-size:11px;font-family:Helvetica;color:#000000;}\n\t.ft101{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft102{font-size:13px;font-family:Helvetica;color:#000000;}\n\t.ft103{font-size:10px;font-family:Helvetica;color:#ffffff;}\n\t.ft104{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft105{font-size:14px;font-family:Helvetica;color:#000000;}\n\t.ft106{font-size:13px;font-family:Times;color:#000000;}\n\t.ft107{font-size:8px;font-family:Helvetica;color:#000000;}\n\t.ft108{font-size:10px;line-height:13px;font-family:Helvetica;color:#000000;}\n\t.ft109{font-size:10px;line-height:13px;font-family:Helvetica;color:#000000;}\n" }} />
        <div id="page10-div" style={{position: 'relative', width: '918px', height: '1188px'}}>
          <img width={918} height={1188} src="target010.png" alt="background image" />
          <p style={{position: 'absolute', top: '34px', left: '720px', whiteSpace: 'nowrap'}} className="ft100"><b>Protected B&nbsp;</b></p>
          <p style={{position: 'absolute', top: '36px', left: '799px', whiteSpace: 'nowrap'}} className="ft101">when completed</p>
          <p style={{position: 'absolute', top: '51px', left: '63px', whiteSpace: 'nowrap'}} className="ft102"><b>Part I tax</b></p>
          <p style={{position: 'absolute', top: '77px', left: '719px', whiteSpace: 'nowrap'}} className="ft103"><b>550</b></p>
          <p style={{position: 'absolute', top: '77px', left: '864px', whiteSpace: 'nowrap'}} className="ft101">A</p>
          <p style={{position: 'absolute', top: '81px', left: '41px', whiteSpace: 'nowrap'}} className="ft101">Base amount Part I tax – Taxable income (from line 360 on page 3)&nbsp;<b>multiplied</b>&nbsp;by 38%&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '113px', left: '41px', whiteSpace: 'nowrap'}} className="ft104"><b>Additional tax&nbsp;on personal services business income&nbsp;</b>(section 123.5)</p>
          <p style={{position: 'absolute', top: '131px', left: '719px', whiteSpace: 'nowrap'}} className="ft103"><b>560</b></p>
          <p style={{position: 'absolute', top: '131px', left: '864px', whiteSpace: 'nowrap'}} className="ft101">B</p>
          <p style={{position: 'absolute', top: '131px', left: '498px', whiteSpace: 'nowrap'}} className="ft103"><b>555</b></p>
          <p style={{position: 'absolute', top: '133px', left: '40px', whiteSpace: 'nowrap'}} className="ft101">Taxable income from a personal services business&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '128px', left: '645px', whiteSpace: 'nowrap'}} className="ft105">×</p>
          <p style={{position: 'absolute', top: '131px', left: '662px', whiteSpace: 'nowrap'}} className="ft101">5%</p>
          <p style={{position: 'absolute', top: '128px', left: '689px', whiteSpace: 'nowrap'}} className="ft105">=</p>
          <p style={{position: 'absolute', top: '171px', left: '719px', whiteSpace: 'nowrap'}} className="ft103"><b>565</b></p>
          <p style={{position: 'absolute', top: '172px', left: '864px', whiteSpace: 'nowrap'}} className="ft101">C</p>
          <p style={{position: 'absolute', top: '173px', left: '41px', whiteSpace: 'nowrap'}} className="ft101">Additional tax on banks and life insurers from Schedule 68&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '189px', left: '719px', whiteSpace: 'nowrap'}} className="ft103"><b>602</b></p>
          <p style={{position: 'absolute', top: '189px', left: '864px', whiteSpace: 'nowrap'}} className="ft101">D</p>
          <p style={{position: 'absolute', top: '194px', left: '41px', whiteSpace: 'nowrap'}} className="ft101">Recapture of investment tax credit from Schedule 31&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '226px', left: '41px', whiteSpace: 'nowrap'}} className="ft108"><b>Calculation for the refundable tax on the Canadian-controlled private corporation's (CCPC) investment income&nbsp;<br /></b>(if it was a CCPC throughout the tax year)</p>
          <p style={{position: 'absolute', top: '261px', left: '698px', whiteSpace: 'nowrap'}} className="ft101">E</p>
          <p style={{position: 'absolute', top: '266px', left: '41px', whiteSpace: 'nowrap'}} className="ft101">Aggregate investment income from line 440 on page 6&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '293px', left: '544px', whiteSpace: 'nowrap'}} className="ft101">F</p>
          <p style={{position: 'absolute', top: '298px', left: '40px', whiteSpace: 'nowrap'}} className="ft101">Taxable income from line 360 on page 3&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '316px', left: '41px', whiteSpace: 'nowrap'}} className="ft104"><b>Deduct:</b></p>
          <p style={{position: 'absolute', top: '342px', left: '545px', whiteSpace: 'nowrap'}} className="ft101">G</p>
          <p style={{position: 'absolute', top: '333px', left: '41px', whiteSpace: 'nowrap'}} className="ft109">Amount from line 400, 405, 410, or 428 on page 4, whichever&nbsp;<br />is the least&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '369px', left: '697px', whiteSpace: 'nowrap'}} className="ft101">H</p>
          <p style={{position: 'absolute', top: '369px', left: '204px', whiteSpace: 'nowrap'}} className="ft101">Net amount (amount F&nbsp;<b>minus</b>&nbsp;amount G)</p>
          <p style={{position: 'absolute', top: '365px', left: '542px', whiteSpace: 'nowrap'}} className="ft106">Ź</p>
          <p style={{position: 'absolute', top: '414px', left: '719px', whiteSpace: 'nowrap'}} className="ft103"><b>604</b></p>
          <p style={{position: 'absolute', top: '414px', left: '864px', whiteSpace: 'nowrap'}} className="ft101">I</p>
          <p style={{position: 'absolute', top: '419px', left: '45px', whiteSpace: 'nowrap'}} className="ft101">Refundable tax on CCPC's investment income – 10 2/3% of whichever is less: amount E or amount H&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '441px', left: '864px', whiteSpace: 'nowrap'}} className="ft101">J</p>
          <p style={{position: 'absolute', top: '441px', left: '526px', whiteSpace: 'nowrap'}} className="ft101">Subtotal (<b>add</b>&nbsp;amounts A, B, C, D, and I)</p>
          <p style={{position: 'absolute', top: '469px', left: '41px', whiteSpace: 'nowrap'}} className="ft104"><b>Deduct:</b></p>
          <p style={{position: 'absolute', top: '486px', left: '698px', whiteSpace: 'nowrap'}} className="ft101">K</p>
          <p style={{position: 'absolute', top: '489px', left: '41px', whiteSpace: 'nowrap'}} className="ft101">Small business deduction from line 430 on page 4&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '504px', left: '552px', whiteSpace: 'nowrap'}} className="ft103"><b>608</b></p>
          <p style={{position: 'absolute', top: '509px', left: '40px', whiteSpace: 'nowrap'}} className="ft101">Federal tax abatement&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '536px', left: '552px', whiteSpace: 'nowrap'}} className="ft103"><b>616</b></p>
          <p style={{position: 'absolute', top: '527px', left: '40px', whiteSpace: 'nowrap'}} className="ft109">Manufacturing and processing profits deduction and zero-emission technology manufacturing&nbsp;<br />deduction from Schedule 27&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '554px', left: '552px', whiteSpace: 'nowrap'}} className="ft103"><b>620</b></p>
          <p style={{position: 'absolute', top: '559px', left: '40px', whiteSpace: 'nowrap'}} className="ft101">Investment corporation deduction&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '576px', left: '314px', whiteSpace: 'nowrap'}} className="ft103"><b>624</b></p>
          <p style={{position: 'absolute', top: '577px', left: '198px', whiteSpace: 'nowrap'}} className="ft101">Taxed capital gains</p>
          <p style={{position: 'absolute', top: '599px', left: '552px', whiteSpace: 'nowrap'}} className="ft103"><b>632</b></p>
          <p style={{position: 'absolute', top: '604px', left: '40px', whiteSpace: 'nowrap'}} className="ft101">Federal foreign non-business income tax credit from Schedule 21&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '617px', left: '552px', whiteSpace: 'nowrap'}} className="ft103"><b>636</b></p>
          <p style={{position: 'absolute', top: '622px', left: '40px', whiteSpace: 'nowrap'}} className="ft101">Federal foreign business income tax credit from Schedule 21&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '635px', left: '552px', whiteSpace: 'nowrap'}} className="ft103"><b>638</b></p>
          <p style={{position: 'absolute', top: '639px', left: '40px', whiteSpace: 'nowrap'}} className="ft101">General tax reduction for CCPCs from amount I on page 5&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '653px', left: '552px', whiteSpace: 'nowrap'}} className="ft103"><b>639</b></p>
          <p style={{position: 'absolute', top: '658px', left: '40px', whiteSpace: 'nowrap'}} className="ft101">General tax reduction from amount P on page 5&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '671px', left: '552px', whiteSpace: 'nowrap'}} className="ft103"><b>640</b></p>
          <p style={{position: 'absolute', top: '676px', left: '40px', whiteSpace: 'nowrap'}} className="ft101">Federal logging tax credit from Schedule 21&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '689px', left: '552px', whiteSpace: 'nowrap'}} className="ft103"><b>641</b></p>
          <p style={{position: 'absolute', top: '694px', left: '40px', whiteSpace: 'nowrap'}} className="ft101">Eligible Canadian bank deduction under section 125.21&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '707px', left: '552px', whiteSpace: 'nowrap'}} className="ft103"><b>648</b></p>
          <p style={{position: 'absolute', top: '712px', left: '40px', whiteSpace: 'nowrap'}} className="ft101">Federal qualifying environmental trust tax credit&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '725px', left: '552px', whiteSpace: 'nowrap'}} className="ft103"><b>652</b></p>
          <p style={{position: 'absolute', top: '730px', left: '40px', whiteSpace: 'nowrap'}} className="ft101">Investment tax credit from Schedule 31&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '743px', left: '869px', whiteSpace: 'nowrap'}} className="ft101">L</p>
          <p style={{position: 'absolute', top: '745px', left: '532px', whiteSpace: 'nowrap'}} className="ft101">Subtotal</p>
          <p style={{position: 'absolute', top: '739px', left: '704px', whiteSpace: 'nowrap'}} className="ft106">Ź</p>
          <p style={{position: 'absolute', top: '774px', left: '869px', whiteSpace: 'nowrap'}} className="ft101">M</p>
          <p style={{position: 'absolute', top: '779px', left: '41px', whiteSpace: 'nowrap'}} className="ft104"><b>Part I tax payable</b>&nbsp;– Amount J&nbsp;<b>minus</b>&nbsp;amount L&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '797px', left: '41px', whiteSpace: 'nowrap'}} className="ft101">Enter amount M on line 700 on page 9.</p>
          <p style={{position: 'absolute', top: '850px', left: '63px', whiteSpace: 'nowrap'}} className="ft102"><b>Privacy notice</b></p>
          <p style={{position: 'absolute', top: '869px', left: '41px', whiteSpace: 'nowrap'}} className="ft109">Personal information (including the SIN) is collected to administer or enforce the Income Tax Act and related programs and activities including administering&nbsp;<br />tax, benefits, audit, compliance, and collection. The information collected may be used or disclosed for the purposes of other&nbsp;federal acts that provide for the&nbsp;<br />imposition and collection of a tax or duty. It may also be disclosed to other federal, provincial, territorial, or foreign government institutions to the extent&nbsp;<br />authorized by law. Failure to provide this information may result in paying interest or penalties, or in other actions. Under the Privacy Act, individuals have a&nbsp;<br />right of protection, access to and correction of their personal information, or to file a complaint with the Privacy Commissioner of Canada regarding the&nbsp;<br />handling of their personal information. Refer to Personal Information Bank CRA PPU 047 on Information about Programs and Information Holdings at<br /><b>canada.ca/cra-information-about-programs</b>.</p>
          <p style={{position: 'absolute', top: '1145px', left: '31px', whiteSpace: 'nowrap'}} className="ft107">T2 E (23)</p>
          <p style={{position: 'absolute', top: '1145px', left: '833px', whiteSpace: 'nowrap'}} className="ft107">Page 8 of 9</p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\tp {margin: 0; padding: 0;}\t.ft110{font-size:11px;font-family:Helvetica;color:#000000;}\n\t.ft111{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft112{font-size:13px;font-family:Helvetica;color:#000000;}\n\t.ft113{font-size:10px;font-family:Helvetica;color:#000000;}\n\t.ft114{font-size:10px;font-family:Helvetica;color:#ffffff;}\n\t.ft115{font-size:13px;font-family:Times;color:#000000;}\n\t.ft116{font-size:8px;font-family:Helvetica;color:#000000;}\n\t.ft117{font-size:10px;line-height:13px;font-family:Helvetica;color:#000000;}\n" }} />
        <div id="page11-div" style={{position: 'relative', width: '918px', height: '1188px'}}>
          <img width={918} height={1188} src="target011.png" alt="background image" />
          <p style={{position: 'absolute', top: '34px', left: '720px', whiteSpace: 'nowrap'}} className="ft110"><b>Protected B&nbsp;</b></p>
          <p style={{position: 'absolute', top: '36px', left: '799px', whiteSpace: 'nowrap'}} className="ft111">when completed</p>
          <p style={{position: 'absolute', top: '42px', left: '63px', whiteSpace: 'nowrap'}} className="ft112"><b>Summary of tax and credits</b></p>
          <p style={{position: 'absolute', top: '64px', left: '41px', whiteSpace: 'nowrap'}} className="ft113"><b>Federal tax</b></p>
          <p style={{position: 'absolute', top: '77px', left: '719px', whiteSpace: 'nowrap'}} className="ft114"><b>700</b></p>
          <p style={{position: 'absolute', top: '81px', left: '50px', whiteSpace: 'nowrap'}} className="ft111">Part I tax payable from amount M on page 8&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '95px', left: '719px', whiteSpace: 'nowrap'}} className="ft114"><b>710</b></p>
          <p style={{position: 'absolute', top: '100px', left: '50px', whiteSpace: 'nowrap'}} className="ft111">Part III.1 tax payable from Schedule 55&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '113px', left: '719px', whiteSpace: 'nowrap'}} className="ft114"><b>712</b></p>
          <p style={{position: 'absolute', top: '118px', left: '50px', whiteSpace: 'nowrap'}} className="ft111">Part IV tax payable from Schedule 3&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '131px', left: '719px', whiteSpace: 'nowrap'}} className="ft114"><b>716</b></p>
          <p style={{position: 'absolute', top: '136px', left: '50px', whiteSpace: 'nowrap'}} className="ft111">Part IV.1 tax payable from Schedule 43&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '149px', left: '719px', whiteSpace: 'nowrap'}} className="ft114"><b>720</b></p>
          <p style={{position: 'absolute', top: '154px', left: '50px', whiteSpace: 'nowrap'}} className="ft111">Part VI tax payable from Schedule 38&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '167px', left: '719px', whiteSpace: 'nowrap'}} className="ft114"><b>724</b></p>
          <p style={{position: 'absolute', top: '172px', left: '50px', whiteSpace: 'nowrap'}} className="ft111">Part VI.1 tax payable from Schedule 43&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '185px', left: '719px', whiteSpace: 'nowrap'}} className="ft114"><b>725</b></p>
          <p style={{position: 'absolute', top: '189px', left: '50px', whiteSpace: 'nowrap'}} className="ft111">Part VI.2 tax payable from Schedule 67&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '203px', left: '719px', whiteSpace: 'nowrap'}} className="ft114"><b>727</b></p>
          <p style={{position: 'absolute', top: '208px', left: '50px', whiteSpace: 'nowrap'}} className="ft111">Part XIII.1 tax payable from Schedule 92&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '221px', left: '719px', whiteSpace: 'nowrap'}} className="ft114"><b>728</b></p>
          <p style={{position: 'absolute', top: '226px', left: '50px', whiteSpace: 'nowrap'}} className="ft111">Part XIV tax payable from Schedule 20&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '239px', left: '656px', whiteSpace: 'nowrap'}} className="ft111">Total federal tax</p>
          <p style={{position: 'absolute', top: '248px', left: '41px', whiteSpace: 'nowrap'}} className="ft113"><b>Add provincial or territorial tax:</b></p>
          <p style={{position: 'absolute', top: '253px', left: '368px', whiteSpace: 'nowrap'}} className="ft114"><b>750</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  type="text" className="borderless-input" style={{width:'100px'}}  defaultValue={state.province}/>
            
          </p>
          <p style={{position: 'absolute', top: '261px', left: '50px', whiteSpace: 'nowrap'}} className="ft111">Provincial or territorial jurisdiction&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '275px', left: '63px', whiteSpace: 'nowrap'}} className="ft111">(if more than one jurisdiction, enter "multiple" and complete Schedule 5)</p>
          <p style={{position: 'absolute', top: '293px', left: '719px', whiteSpace: 'nowrap'}} className="ft114"><b>760</b></p>
          <p style={{position: 'absolute', top: '298px', left: '50px', whiteSpace: 'nowrap'}} className="ft111">Net provincial or territorial tax payable (except Quebec and Alberta)&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '311px', left: '719px', whiteSpace: 'nowrap'}} className="ft114"><b>770</b></p>
          <p style={{position: 'absolute', top: '311px', left: '864px', whiteSpace: 'nowrap'}} className="ft111">A</p>
          <p style={{position: 'absolute', top: '311px', left: '615px', whiteSpace: 'nowrap'}} className="ft111">Total tax payable</p>
          <p style={{position: 'absolute', top: '316px', left: '41px', whiteSpace: 'nowrap'}} className="ft113"><b>Deduct other credits:</b></p>
          <p style={{position: 'absolute', top: '329px', left: '539px', whiteSpace: 'nowrap'}} className="ft114"><b>780</b></p>
          <p style={{position: 'absolute', top: '334px', left: '49px', whiteSpace: 'nowrap'}} className="ft111">Investment tax credit refund from Schedule 31&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '347px', left: '539px', whiteSpace: 'nowrap'}} className="ft114"><b>784</b></p>
          <p style={{position: 'absolute', top: '351px', left: '49px', whiteSpace: 'nowrap'}} className="ft111">Dividend refund from amount JJ on page 7.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '365px', left: '539px', whiteSpace: 'nowrap'}} className="ft114"><b>788</b></p>
          <p style={{position: 'absolute', top: '370px', left: '49px', whiteSpace: 'nowrap'}} className="ft111">Federal capital gains refund from Schedule 18&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '383px', left: '539px', whiteSpace: 'nowrap'}} className="ft114"><b>792</b></p>
          <p style={{position: 'absolute', top: '388px', left: '49px', whiteSpace: 'nowrap'}} className="ft111">Federal qualifying environmental trust tax credit refund&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '401px', left: '539px', whiteSpace: 'nowrap'}} className="ft114"><b>795</b></p>
          <p style={{position: 'absolute', top: '405px', left: '49px', whiteSpace: 'nowrap'}} className="ft111">Return of fuel charge proceeds to farmers tax credit from Schedule 63&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '419px', left: '539px', whiteSpace: 'nowrap'}} className="ft114"><b>796</b></p>
          <p style={{position: 'absolute', top: '424px', left: '49px', whiteSpace: 'nowrap'}} className="ft111">Canadian film or video production tax credit (Form T1131)&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '437px', left: '539px', whiteSpace: 'nowrap'}} className="ft114"><b>797</b></p>
          <p style={{position: 'absolute', top: '442px', left: '49px', whiteSpace: 'nowrap'}} className="ft111">Film or video production services tax credit (Form T1177)&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '455px', left: '539px', whiteSpace: 'nowrap'}} className="ft114"><b>798</b></p>
          <p style={{position: 'absolute', top: '459px', left: '49px', whiteSpace: 'nowrap'}} className="ft111">Canadian journalism labour tax credit from Schedule 58&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '473px', left: '539px', whiteSpace: 'nowrap'}} className="ft114"><b>799</b></p>
          <p style={{position: 'absolute', top: '477px', left: '49px', whiteSpace: 'nowrap'}} className="ft111">Air quality improvement tax credit from Schedule 65&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '491px', left: '539px', whiteSpace: 'nowrap'}} className="ft114"><b>800</b></p>
          <p style={{position: 'absolute', top: '496px', left: '49px', whiteSpace: 'nowrap'}} className="ft111">Tax withheld at source&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '513px', left: '368px', whiteSpace: 'nowrap'}} className="ft114"><b>801</b></p>
          <p style={{position: 'absolute', top: '518px', left: '59px', whiteSpace: 'nowrap'}} className="ft111">Total payments on which tax has been withheld&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '531px', left: '539px', whiteSpace: 'nowrap'}} className="ft114"><b>808</b></p>
          <p style={{position: 'absolute', top: '536px', left: '49px', whiteSpace: 'nowrap'}} className="ft111">Provincial and territorial capital gains refund from Schedule 18&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '549px', left: '539px', whiteSpace: 'nowrap'}} className="ft114"><b>812</b></p>
          <p style={{position: 'absolute', top: '554px', left: '49px', whiteSpace: 'nowrap'}} className="ft111">Provincial and territorial refundable tax credits from Schedule 5&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '567px', left: '539px', whiteSpace: 'nowrap'}} className="ft114"><b>840</b></p>
          <p style={{position: 'absolute', top: '572px', left: '49px', whiteSpace: 'nowrap'}} className="ft111">Tax instalments paid&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '585px', left: '539px', whiteSpace: 'nowrap'}} className="ft114"><b>890</b></p>
          <p style={{position: 'absolute', top: '586px', left: '864px', whiteSpace: 'nowrap'}} className="ft111">B</p>
          <p style={{position: 'absolute', top: '586px', left: '461px', whiteSpace: 'nowrap'}} className="ft111">Total credits&nbsp;</p>
          <p style={{position: 'absolute', top: '581px', left: '681px', whiteSpace: 'nowrap'}} className="ft115">Ź</p>
          <p style={{position: 'absolute', top: '610px', left: '548px', whiteSpace: 'nowrap'}} className="ft111">Balance (amount A&nbsp;<b>minus</b>&nbsp;amount B)</p>
          <p style={{position: 'absolute', top: '635px', left: '361px', whiteSpace: 'nowrap'}} className="ft111">If the result is negative, you have a&nbsp;<b>refund</b>. If the result is positive, you have a&nbsp;<b>balance owing</b>.</p>
          <p style={{position: 'absolute', top: '653px', left: '597px', whiteSpace: 'nowrap'}} className="ft111">Enter the amount below on whichever line applies.</p>
          <p style={{position: 'absolute', top: '684px', left: '385px', whiteSpace: 'nowrap'}} className="ft111">&nbsp;Generally, the CRA does not charge or refund a difference of $2 or less.</p>
          <p style={{position: 'absolute', top: '684px', left: '344px', whiteSpace: 'nowrap'}} className="ft115">ź</p>
          <p style={{position: 'absolute', top: '702px', left: '249px', whiteSpace: 'nowrap'}} className="ft111">Refund</p>
          <p style={{position: 'absolute', top: '702px', left: '125px', whiteSpace: 'nowrap'}} className="ft114"><b>894</b></p>
          <p style={{position: 'absolute', top: '703px', left: '44px', whiteSpace: 'nowrap'}} className="ft111">Refund code</p>
          <p style={{position: 'absolute', top: '724px', left: '344px', whiteSpace: 'nowrap'}} className="ft115">ź</p>
          <p style={{position: 'absolute', top: '738px', left: '41px', whiteSpace: 'nowrap'}} className="ft111">For information on how to enrol for direct deposit, go to&nbsp;<b>canada.ca/cra-direct-deposit</b>.</p>
          <p style={{position: 'absolute', top: '684px', left: '798px', whiteSpace: 'nowrap'}} className="ft115">ź</p>
          <p style={{position: 'absolute', top: '707px', left: '585px', whiteSpace: 'nowrap'}} className="ft111">Balance owing</p>
          <p style={{position: 'absolute', top: '707px', left: '675px', whiteSpace: 'nowrap'}} className="ft111">.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '724px', left: '798px', whiteSpace: 'nowrap'}} className="ft115">ź</p>
          <p style={{position: 'absolute', top: '738px', left: '576px', whiteSpace: 'nowrap'}} className="ft117">For information on how to make your payment, go to&nbsp;<br /><b>canada.ca/payments</b>.</p>
          <p style={{position: 'absolute', top: '774px', left: '674px', whiteSpace: 'nowrap'}} className="ft114"><b>896</b></p>
          <p style={{position: 'absolute', top: '765px', left: '40px', whiteSpace: 'nowrap'}} className="ft117">If the corporation is a Canadian-controlled private corporation throughout the tax year, does it qualify for the&nbsp;<br />one-month extension of the date the balance of tax is due?&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '774px', left: '715px', whiteSpace: 'nowrap'}} className="ft111">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '774px', left: '801px', whiteSpace: 'nowrap'}} className="ft111">&nbsp; &nbsp;No</p>
          <p style={{position: 'absolute', top: '797px', left: '40px', whiteSpace: 'nowrap'}} className="ft111">If this return was prepared by a tax preparer for a fee, provide their:</p>
          <p style={{position: 'absolute', top: '801px', left: '719px', whiteSpace: 'nowrap'}} className="ft114"><b>920</b></p>
          <p style={{position: 'absolute', top: '806px', left: '401px', whiteSpace: 'nowrap'}} className="ft111">EFILE number&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '819px', left: '719px', whiteSpace: 'nowrap'}} className="ft114"><b>925</b></p>
          <p style={{position: 'absolute', top: '824px', left: '401px', whiteSpace: 'nowrap'}} className="ft111">RepID&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '852px', left: '63px', whiteSpace: 'nowrap'}} className="ft112"><b>Certification</b></p>
          <p style={{position: 'absolute', top: '872px', left: '57px', whiteSpace: 'nowrap'}} className="ft114"><b>950</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  type="text" className="borderless-input" style={{width:'180px'}}  defaultValue={state.last_name}/>
          
          </p>
          <p style={{position: 'absolute', top: '878px', left: '41px', whiteSpace: 'nowrap'}} className="ft111">I,</p>
          <p style={{position: 'absolute', top: '895px', left: '176px', whiteSpace: 'nowrap'}} className="ft116">Last name</p>
          <p style={{position: 'absolute', top: '872px', left: '332px', whiteSpace: 'nowrap'}} className="ft114"><b>951</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  type="text" className="borderless-input" style={{width:'180px'}}  defaultValue={state.first_name}/>
          </p>
          <p style={{position: 'absolute', top: '895px', left: '450px', whiteSpace: 'nowrap'}} className="ft116">First name</p>
          <p style={{position: 'absolute', top: '874px', left: '606px', whiteSpace: 'nowrap'}} className="ft114"><b>954</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  type="text" className="borderless-input" style={{width:'180px'}}  defaultValue={state.position}/>
          </p>
          <p style={{position: 'absolute', top: '895px', left: '696px', whiteSpace: 'nowrap'}} className="ft116">Position, office, or rank</p>
          <p style={{position: 'absolute', top: '878px', left: '864px', whiteSpace: 'nowrap'}} className="ft111">,</p>
          <p style={{position: 'absolute', top: '914px', left: '41px', whiteSpace: 'nowrap'}} className="ft117">am an authorized signing officer of the corporation. I certify that I have examined this return, including accompanying schedules and statements, and that&nbsp;<br />the information given on this return is, to the best of my knowledge, correct and complete. I also certify that the method of calculating income for this tax&nbsp;<br />year is consistent with that of the previous tax year except as specifically disclosed in a statement attached to this return.</p>
          <p style={{position: 'absolute', top: '960px', left: '57px', whiteSpace: 'nowrap'}} className="ft114"><b>955</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  type="text" className="borderless-input" style={{width:'180px'}}  defaultValue={currenDate}/>
          </p>
          <p style={{position: 'absolute', top: '980px', left: '100px', whiteSpace: 'nowrap'}} className="ft116">Date (yyyy/mm/dd)</p>
          <p style={{position: 'absolute', top: '980px', left: '300px', whiteSpace: 'nowrap'}} className="ft116">Signature of the authorized signing officer of the corporation</p>
          <p style={{position: 'absolute', top: '960px', left: '674px', whiteSpace: 'nowrap'}} className="ft114"><b>956</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  type="text" className="borderless-input" style={{width:'180px'}}  defaultValue={state.phone}/>
          </p>
          <p style={{position: 'absolute', top: '980px', left: '743px', whiteSpace: 'nowrap'}} className="ft116">Telephone number</p>
         {state.contact_ == "YES"?
          <p style={{position: 'absolute', top: '998px', left: '674px', whiteSpace: 'nowrap'}} className="ft114"><b>957</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue='x'/>
          &nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
          </p>:
           <p style={{position: 'absolute', top: '998px', left: '672px', whiteSpace: 'nowrap'}} className="ft114"><b>957</b>
           &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  style={{width:'15px'}} type="text" className="borderless-input" defaultValue=''/>
           &nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input style={{width:'15px'}} type="text" className="borderless-input" defaultValue='x'/>
           </p>}
          <p style={{position: 'absolute', top: '1009px', left: '40px', whiteSpace: 'nowrap'}} className="ft111">Is the contact person the same as the authorized signing officer? If&nbsp;<b>no</b>, complete the information below&nbsp;&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.&nbsp;.</p>
          <p style={{position: 'absolute', top: '1003px', left: '715px', whiteSpace: 'nowrap'}} className="ft111">&nbsp; &nbsp;Yes</p>
          <p style={{position: 'absolute', top: '1003px', left: '801px', whiteSpace: 'nowrap'}} className="ft111">&nbsp; &nbsp;No</p>
          <p style={{position: 'absolute', top: '1027px', left: '57px', whiteSpace: 'nowrap'}} className="ft114"><b>958</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  type="text" className="borderless-input" style={{width:'180px'}}  defaultValue={state.signing_officer}/>
          </p>
          <p style={{position: 'absolute', top: '1048px', left: '299px', whiteSpace: 'nowrap'}} className="ft116">Name of other authorized person</p>
          <p style={{position: 'absolute', top: '1027px', left: '674px', whiteSpace: 'nowrap'}} className="ft114"><b>959</b>
          &nbsp;&nbsp;&nbsp;;&nbsp;;&nbsp;;&nbsp;;&nbsp;<input  type="text" className="borderless-input" style={{width:'180px'}}  defaultValue={state.signing_phone}/>
          </p>
          <p style={{position: 'absolute', top: '1048px', left: '743px', whiteSpace: 'nowrap'}} className="ft116">Telephone number</p>
          <p style={{position: 'absolute', top: '1075px', left: '63px', whiteSpace: 'nowrap'}} className="ft112"><b>Language of correspondence – Langue de correspondance</b></p>
          <p style={{position: 'absolute', top: '1103px', left: '674px', whiteSpace: 'nowrap'}} className="ft114"><b>990</b></p>
          <p style={{position: 'absolute', top: '1098px', left: '40px', whiteSpace: 'nowrap'}} className="ft111">Indicate your language of correspondence by entering&nbsp;<b>1</b>&nbsp;for English or&nbsp;<b>2</b>&nbsp;for French.&nbsp;</p>
          <p style={{position: 'absolute', top: '1116px', left: '40px', whiteSpace: 'nowrap'}} className="ft111">Indiquez votre langue de correspondance en inscrivant&nbsp;<b>1</b>&nbsp;pour anglais ou&nbsp;<b>2</b>&nbsp;pour français.</p>
          <p style={{position: 'absolute', top: '1145px', left: '31px', whiteSpace: 'nowrap'}} className="ft116">T2 E (23)</p>
          <p style={{position: 'absolute', top: '1145px', left: '833px', whiteSpace: 'nowrap'}} className="ft116">Page 9 of 9</p>
        </div>
      </div>
                        
              
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>

                  
                </div>
              </div>
            </div>
            <div className="footer py-4 d-flex flex-lg-column" id="kt_footer" style={{bottom :'0', position: 'fixed'}}>
              <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
                <div className="text-dark order-2 order-md-1">
                  <span className="text-muted fw-semibold me-1">2022©</span>
                  <a href="https://titan-xchange.com/" target="_blank" className="text-gray-800 text-hover-primary">Smartreturn</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
              </div>
               
        );
 
  };

export default Changepassword;
