import React from 'react';

function PrintButton() {
  const handlePrint = () => {
    const printableContent = document.getElementById('printable-content');
    const printWindow = window.open('', '', 'width=600,height=600');
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Printable Content</title>
        </head>
        <body>
          ${printableContent.innerHTML}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  return (
    <button onClick={handlePrint}>Print</button>
  );
}

export default PrintButton;
